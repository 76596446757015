import {
  useRecordContext,
  RecordContextProvider,
  NumberField,
  TabbedShowLayout,
  useTranslate,
} from "react-admin";
import Box from "@mui/material/Box";

const SaleReturnExpandList = ({ forReport }) => {
  const record = useRecordContext();
  const t = useTranslate();
  return (
    <TabbedShowLayout
      syncWithLocation={false}
      sx={{
        m: 5,
        backgroundClip: "padding-box",
        boxShadow: (theme) => {
          return theme.components.MuiPaper.styleOverrides.elevation1.boxShadow;
        },
        borderRadius: "4px",
        bgcolor: "background.paper",
      }}
    >
      <TabbedShowLayout.Tab label={t("mine.tbl_header.product")}>
        <Box
          component="table"
          sx={{
            width: "100%",
            // bgcolor: "rgba(0,0,0,.04)",
            fontSize: "smaller",
            borderCollapse: "collapse",
            "& th, td": {
              padding: "2px 8px",
              border: "1px solid #dddddd",
            },
          }}
        >
          <thead>
            <tr>
              <th>SJ. Number</th>
              <th>{t("mine.tbl_header.product_code")}</th>
              <th>{t("mine.tbl_header.product_name")}</th>
              <th style={{ textAlign: "right" }}>{t("mine.label.cogs")}</th>
              <th style={{ textAlign: "right" }}>{t("mine.label.sent")}</th>
              <th style={{ textAlign: "right" }}>{t("mine.label.return")}</th>
              <th style={{ textAlign: "right" }}>
                {t("mine.tbl_header.price")}
              </th>
              <th style={{ textAlign: "right" }}>
                {t("mine.label.discount_per_box")}
              </th>
              <th style={{ textAlign: "right" }}>
                {t("mine.label.special_discount_per_box")}
              </th>
              <th style={{ textAlign: "right" }}>
                {t("mine.label.transport_discount")}
              </th>
              <th style={{ textAlign: "right" }}>Subtotal</th>
              <th>{t("mine.tbl_header.warehouse")}</th>
            </tr>
          </thead>
          <tbody>
            {record.sale_return_items.map((item, idx) => {
              if (item.sale_item.sale_item_hpps) {
                return item.sale_item.sale_item_hpps.map((hpp, index) => {
                  return (
                    <tr key={item.id + idx}>
                      <td>{item.sale_item.sale.code}</td>
                      <td>{item.sale_item.sale_order_item.product.code}</td>
                      <td>{item.sale_item.sale_order_item.product.name}</td>
                      <td style={{ textAlign: "right" }}>
                        <RecordContextProvider value={hpp}>
                          <NumberField
                            source="inventory.cogs"
                            sx={{
                              fontSize: "inherit",
                            }}
                          />
                        </RecordContextProvider>
                      </td>
                      <td style={{ textAlign: "right" }}>
                        <RecordContextProvider value={hpp}>
                          <NumberField
                            source="quantity"
                            sx={{
                              fontSize: "inherit",
                            }}
                          />
                        </RecordContextProvider>
                      </td>
                      <td style={{ textAlign: "right" }}>
                        <RecordContextProvider value={hpp}>
                          <NumberField
                            source="return"
                            sx={{
                              fontSize: "inherit",
                            }}
                          />
                        </RecordContextProvider>
                      </td>
                      <td style={{ textAlign: "right" }}>
                        <RecordContextProvider value={item}>
                          <NumberField
                            source="sale_item.sale_order_item.price"
                            sx={{
                              fontSize: "inherit",
                            }}
                          />
                        </RecordContextProvider>
                      </td>
                      <td style={{ textAlign: "right" }}>
                        <RecordContextProvider value={item}>
                          <NumberField
                            source="sale_item.disc"
                            sx={{
                              fontSize: "inherit",
                            }}
                          />
                        </RecordContextProvider>
                      </td>
                      <td style={{ textAlign: "right" }}>
                        <RecordContextProvider value={item}>
                          <NumberField
                            source="sale_item.spc_disc"
                            sx={{
                              fontSize: "inherit",
                            }}
                          />
                        </RecordContextProvider>
                      </td>
                      <td style={{ textAlign: "right" }}>
                        <RecordContextProvider value={item}>
                          <NumberField
                            emptyText="0"
                            transform={(val) => (+val < 0 ? +val * -1 : val)}
                            source="sale_item.transport_cost"
                            sx={{
                              fontSize: "inherit",
                            }}
                          />
                        </RecordContextProvider>
                      </td>
                      <td style={{ textAlign: "right" }}>
                        <RecordContextProvider value={hpp}>
                          <NumberField
                            source="subtotal_return_price"
                            sx={{
                              fontSize: "inherit",
                            }}
                          />
                        </RecordContextProvider>
                      </td>
                      <td>{item.warehouse.name}</td>
                    </tr>
                  );
                });
              } else {
                return (
                  <tr key={item.id + idx}>
                    <td>{item.sale_item.sale.code}</td>
                    <td>{item.sale_item.sale_order_item.product.code}</td>
                    <td>{item.sale_item.sale_order_item.product.name}</td>
                    <td style={{ textAlign: "right" }}>-</td>
                    <td style={{ textAlign: "right" }}>
                      <RecordContextProvider value={item}>
                        <NumberField
                          source="sale_item.sent_quantity"
                          sx={{
                            fontSize: "inherit",
                          }}
                        />
                      </RecordContextProvider>
                    </td>
                    <td style={{ textAlign: "right" }}>
                      <RecordContextProvider value={item}>
                        <NumberField
                          source="return_quantity"
                          sx={{
                            fontSize: "inherit",
                          }}
                        />
                      </RecordContextProvider>
                    </td>
                    <td style={{ textAlign: "right" }}>
                      <RecordContextProvider value={item}>
                        <NumberField
                          source="sale_item.sale_order_item.price"
                          sx={{
                            fontSize: "inherit",
                          }}
                        />
                      </RecordContextProvider>
                    </td>
                    <td style={{ textAlign: "right" }}>
                      <RecordContextProvider value={item}>
                        <NumberField
                          source="sale_item.disc"
                          sx={{
                            fontSize: "inherit",
                          }}
                        />
                      </RecordContextProvider>
                    </td>
                    <td style={{ textAlign: "right" }}>
                      <RecordContextProvider value={item}>
                        <NumberField
                          source="sale_item.spc_disc"
                          sx={{
                            fontSize: "inherit",
                          }}
                        />
                      </RecordContextProvider>
                    </td>
                    <td style={{ textAlign: "right" }}>
                      <RecordContextProvider value={item}>
                        <NumberField
                          emptyText="0"
                          transform={(val) => (+val < 0 ? +val * -1 : val)}
                          source="sale_item.transport_cost"
                          sx={{
                            fontSize: "inherit",
                          }}
                        />
                      </RecordContextProvider>
                    </td>
                    <td style={{ textAlign: "right" }}>
                      <RecordContextProvider value={item}>
                        <NumberField
                          source="subtotal"
                          sx={{
                            fontSize: "inherit",
                          }}
                        />
                      </RecordContextProvider>
                    </td>
                    <td>{item.warehouse.name}</td>
                  </tr>
                );
              }
            })}
          </tbody>
        </Box>
      </TabbedShowLayout.Tab>
      {!forReport && record.transactions.length ? (
        <TabbedShowLayout.Tab label={t("mine.tbl_header.journal")}>
          <Box
            component="table"
            sx={{
              width: "100%",
              // bgcolor: "rgba(0,0,0,.04)",
              fontSize: "smaller",
              borderCollapse: "collapse",
              "& th, td": {
                padding: "6px 8px",
                border: "1px solid #dddddd",
              },
            }}
          >
            <thead>
              <tr>
                <th>COA</th>
                <th>{t("mine.tbl_header.coa_name")}</th>
                <th>{t("mine.tbl_header.description")}</th>
                <th style={{ textAlign: "right" }}>
                  {t("mine.tbl_header.debit")}
                </th>
                <th style={{ textAlign: "right" }}>
                  {t("mine.tbl_header.credit")}
                </th>
              </tr>
            </thead>
            <tbody>
              {record.transactions.map((item, idx) => {
                return (
                  <tr key={item.id + idx}>
                    <td>{item.coa.code}</td>
                    <td>{item.coa.name}</td>
                    <td>{item.description}</td>
                    <td style={{ textAlign: "right" }}>
                      <RecordContextProvider value={item}>
                        <NumberField
                          source="debit"
                          sx={{
                            fontSize: "inherit",
                          }}
                        />
                      </RecordContextProvider>
                    </td>
                    <td style={{ textAlign: "right" }}>
                      <RecordContextProvider value={item}>
                        <NumberField
                          source="credit"
                          sx={{
                            fontSize: "inherit",
                          }}
                        />
                      </RecordContextProvider>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Box>
        </TabbedShowLayout.Tab>
      ) : null}
    </TabbedShowLayout>
  );
};

export default SaleReturnExpandList;
