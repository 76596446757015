import { useEffect, useMemo } from "react";
import {
  List,
  TextField,
  DatagridConfigurable,
  useTheme,
  NumberField,
  usePermissions,
  useTranslate,
} from "react-admin";
import getFirstAndLastDateOfCurrentMonth from "../../utils/firstLastDate";
import DownloadAction from "../../components/tableListActions/DownloadAction";
import AccountReceivableAgingExpandList from "../../components/accountReceivableAgings/expand/AccountReceivableAgingExpandList";
import accountReceivableAgingFilterList from "../../components/accountReceivableAgings/filter/accountReceivableAgingFilterList";
import AccountReceivableAgingAsideList from "../../components/accountReceivableAgings/aside/AccountReceivableAgingAsideList";

const [firstDate, lastDate] = getFirstAndLastDateOfCurrentMonth();

const AccountReceivableAgingList = () => {
  const { permissions } = usePermissions();
  const [theme] = useTheme();
  const t = useTranslate();

  const dayTrans = t("mine.label.day");

  const list = useMemo(
    () => (
      <List
        storeKey={false} // Disabling Parameters Persistence
        title="mine.list_title.account_receivable_aging"
        empty={false}
        aside={<AccountReceivableAgingAsideList />}
        actions={<DownloadAction />}
        filters={accountReceivableAgingFilterList(permissions)}
        filterDefaultValues={{
          start_date: firstDate,
          end_date: lastDate,
          cut_off: lastDate,
          kind: "per_surat_jalan",
        }}
        queryOptions={{ meta: { sort: ["customers.code ASC"] } }} // overide default sort
        sx={{
          flexDirection: "column",
        }}
      >
        <DatagridConfigurable
          bulkActionButtons={false}
          // rowClick={rowClickHandler}
          expandSingle={true}
          isRowExpandable={(row) =>
            !!row.customer_debts || !!row.sale_orders || !!row.sale_items
          } // sale_orders jika jenisnya per salesman, sale_items kalau per jenis
          expand={<AccountReceivableAgingExpandList />}
          sx={{
            "& .RaDatagrid-expandedPanel > td": {
              p: 0,
              bgcolor: theme !== "dark" ? "#E7EBF0" : "grey.800",
            },
          }}
        >
          <TextField
            source="code"
            label="mine.label.code"
            emptyText="-"
            sx={{ width: "5%" }}
          />
          <TextField source="name" label="mine.label.name" />
          <NumberField source="total_ar" label="mine.label.customer_debt" />
          <NumberField source="total_overdue" label="Overdue" />
          <NumberField source="total_less_30" label={"< 30 " + dayTrans} />
          <NumberField
            source="total_between_30_60"
            label={"30 - 60 " + dayTrans}
          />
          <NumberField
            source="total_between_61_90"
            label={"61 - 90 " + dayTrans}
          />
          <NumberField
            source="total_between_91_120"
            label={"91 - 120 " + dayTrans}
          />
          <NumberField
            source="total_between_121_150"
            label={"121 - 150 " + dayTrans}
          />
          <NumberField source="total_above_150" label={"> 150 " + dayTrans} />
        </DatagridConfigurable>
      </List>
    ),
    [theme, permissions]
  );

  return list;
};

export default AccountReceivableAgingList;
