import { useMemo } from "react";
import {
  List,
  TextField,
  DatagridConfigurable,
  DateField,
  NumberField,
  useTheme,
  usePermissions,
  FunctionField,
  RecordContextProvider,
} from "react-admin";
import getFirstAndLastDateOfCurrentMonth from "../../utils/firstLastDate";
import DownloadAction from "../../components/tableListActions/DownloadAction";
import PurchaseNetCardExpandList from "../../components/purchaseNetCards/expand/PurchaseNetCardExpandList";
import PurchaseReturnCardList from "../purchaseReturnCards/PurchaseReturnCardList";
import PurchaseNetCardAsideList from "../../components/purchaseNetCards/aside/PurchaseNetCardAsideList";
import purchaseNetCardFilterList from "../../components/purchaseNetCards/filter/purchaseNetCardFilterList";
import MyDatagridBody from "../../components/common/MyDatagridBody";
import PurchaseNetCardFooterList from "../../components/purchaseNetCards/footer/PurchaseNetCardFooterList";

const [firstDate, lastDate] = getFirstAndLastDateOfCurrentMonth();

const PurchaseNetCardList = (props) => {
  const { permissions } = usePermissions();
  const [theme] = useTheme();

  const list = useMemo(() => {
    return (
      <>
        <List
          storeKey={false}
          title={props.title ? props.title : undefined}
          empty={false}
          // aside={
          //   <PurchaseNetCardAsideList
          //     name="TOTAL PEMBELIAN"
          //     filter={{ approved: 1 }}
          //   />
          // }
          actions={<DownloadAction />}
          filters={purchaseNetCardFilterList(permissions)}
          filter={{
            approved: 1,
          }}
          filterDefaultValues={{
            start_date: firstDate,
            end_date: lastDate,
            approved: 1,
          }}
          queryOptions={{ meta: { sort: ["created_at DESC"] } }} // overide default sort
          sx={{
            flexDirection: "column",
          }}
        >
          <DatagridConfigurable
            body={<MyDatagridBody footer={<PurchaseNetCardFooterList />} />}
            bulkActionButtons={false}
            expand={<PurchaseNetCardExpandList />}
            sx={{
              "& .RaDatagrid-expandedPanel > td": {
                p: 0,
                bgcolor: theme !== "dark" ? "#E7EBF0" : "grey.800",
              },
            }}
          >
            {permissions && permissions.update_company ? (
              <TextField label="mine.label.company" source="company.alias" />
            ) : null}
            <TextField source="code" label="mine.label.code" />
            <DateField source="date" label="mine.label.date" />
            <TextField label="mine.label.supplier" source="supplier.name" />
            <TextField
              label="mine.label.delivery_note"
              source="delivery_note"
            />
            <FunctionField
              textAlign="right"
              label="Box"
              render={(record) => {
                let rec = { total_received: 0 };
                for (let i = 0; i < record.purchase_items.length; i++) {
                  rec.total_received +=
                    +record.purchase_items[i].received_quantity;
                }
                return (
                  <RecordContextProvider value={rec}>
                    <NumberField source="total_received" />
                  </RecordContextProvider>
                );
              }}
            />
            <NumberField source="total" label="mine.label.value" />
            <TextField source="note" label="mine.label.note" />
          </DatagridConfigurable>
        </List>
        <div style={{ margin: "22px 0 8px 0" }} /> {/* ================ */}
        <PurchaseReturnCardList
          withActions={false}
          withFilters={false}
          grandTotalResource="purchase-net-cards"
          title=" "
        />
      </>
    );
  }, [theme]);
  return list;
};

export default PurchaseNetCardList;
