import { useEffect, useMemo, useState } from "react";
import {
  List,
  TextField,
  WrapperField,
  DatagridConfigurable,
  WithRecord,
  DateField,
  NumberField,
  BooleanField,
  FunctionField,
  useTranslate,
  BulkDeleteWithConfirmButton,
  useResourceContext,
  useTheme,
  RecordContextProvider,
  ChipField,
  usePermissions,
} from "react-admin";
import SaleReturnAddDialog from "../../components/saleReturns/create/SaleReturnAddDialog";
import SaleReturnEditDialog from "../../components/saleReturns/edit/SaleReturnEditDialog";
import saleReturnFilterList from "../../components/saleReturns/filter/saleReturnFilterList";
import TableListActions from "../../components/tableListActions/TableListActions";
import SaleReturnExpandList from "../../components/saleReturns/expand/SaleReturnExpandList";
import EditBtn from "../../components/editBtn/EditBtn";
import ConfirmBtn from "../../components/confirmBtn/ConfirmBtn";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import PrintBtn from "../../components/printBtn/PrintBtn";
import PrintHandler from "../../components/printHandler/PrintHandler";
import getFirstAndLastDateOfCurrentMonth from "../../utils/firstLastDate";
import PurchaseAsideList from "../../components/purchases/aside/PurchaseAsideList";
import DownloadAction from "../../components/tableListActions/DownloadAction";
import PrintContent from "../../components/saleReturns/print/PrintContent";

const [firstDate, lastDate] = getFirstAndLastDateOfCurrentMonth();

const SaleReturnList = () => {
  const resource = useResourceContext();
  const translate = useTranslate();
  const { permissions } = usePermissions();
  const [openAdd, setOpenAdd] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [recordId, setRecordId] = useState();
  const [showAside, setShowAside] = useState(false);
  const [resetPrintContent, setResetPrintContent] = useState();
  const [printRecord, setPrintRecord] = useState({}); // initial value wajib object kosong untuk cek key id di dependency useeffect DeliveryNote.js
  const [theme] = useTheme();

  useEffect(() => {
    setShowAside(resource === "sale-return-cards");
  }, [resource]);

  const handleClickOpenAdd = () => {
    setOpenAdd(true);
  };
  const handleClickOpenEdit = (recId) => () => {
    setRecordId(recId);
    setOpenEdit(true);
  };
  const rowClickHandler = (id, basePath, record) => {
    setShowAside((prev) => !prev);
  };

  const forReport = resource === "sale-return-cards";

  const list = useMemo(() => {
    let actions = (
      <TableListActions
        createHandler={handleClickOpenAdd}
        filter={false}
        exporter={false}
      />
    );

    let filterDefaultValues = {
      start_date: firstDate,
      end_date: lastDate,
    };

    let bulkActionButtons = <BulkDeleteWithConfirmButton />;

    let boolAct = [
      <BooleanField
        key={1}
        source="approved"
        label="mine.tbl_header.approved"
        textAlign="center"
      />,
      <WrapperField key={2} label="mine.tbl_header.actions" textAlign="center">
        <WithRecord
          label="author"
          render={(record) => {
            return !record.approved ? (
              <>
                <EditBtn
                  editHandler={handleClickOpenEdit(record.id)}
                  sx={{ mr: 1 }}
                />
                <ConfirmBtn
                  title="mine.message.approve"
                  content="mine.message.approve_content"
                  label="mine.btn.approve"
                  resource={resource + "/approve"}
                  startIcon={<DoneIcon />}
                />
              </>
            ) : (
              <>
                <PrintBtn
                  dialogProps={{ title: "mine.dialog.print" }}
                  recordId={record.id}
                  setPrintRecord={setPrintRecord}
                  setResetPrintContent={setResetPrintContent}
                />
                {permissions.administrator ? (
                  <ConfirmBtn
                    title="mine.message.unapprove"
                    content="mine.message.unapprove_content"
                    label="mine.label.unapprove"
                    resource={resource + "/unapprove"}
                    startIcon={<CloseIcon />}
                    color="error"
                  />
                ) : null}
              </>
            );
          }}
        />
      </WrapperField>,
    ];

    if (forReport) {
      actions = <DownloadAction />;
      filterDefaultValues.approved = 1;
      bulkActionButtons = false;
      boolAct = null;
    }

    return (
      <List
        title="mine.list_title.sale_return"
        empty={false}
        aside={showAside || forReport ? <PurchaseAsideList /> : null}
        actions={actions}
        filters={saleReturnFilterList(permissions)}
        filterDefaultValues={filterDefaultValues}
        queryOptions={{ meta: { sort: ["created_at DESC"] } }} // overide default sort
        sx={{
          flexDirection: "column",
        }}
      >
        <DatagridConfigurable
          bulkActionButtons={bulkActionButtons}
          rowClick={rowClickHandler}
          expand={<SaleReturnExpandList forReport={forReport} />}
          sx={{
            "& .RaDatagrid-expandedPanel > td": {
              p: 0,
              bgcolor: theme !== "dark" ? "#E7EBF0" : "grey.800",
            },
          }}
        >
          {permissions && permissions.update_company ? (
            <TextField label="mine.label.company" source="company.alias" />
          ) : null}
          <TextField source="code" label="mine.label.code" />
          <DateField source="date" label="mine.label.date" />
          <FunctionField
            label="mine.label.customer"
            render={(record) =>
              `${record.customer.name} (${record.customer.code})`
            }
          />
          <FunctionField
            label="mine.tbl_header.type"
            textAlign="center"
            render={(record) => {
              let rec = {};
              switch (record.type) {
                case 1:
                  rec.type = translate("mine.radio_btn.return_warehouse");
                  rec.color = "success";
                  break;
                case 2:
                  rec.type = translate("mine.radio_btn.return_broken");
                  rec.color = "error";
                  break;
                default:
                  rec.type = "-";
                  rec.color = "default";
              }
              return (
                <RecordContextProvider value={rec}>
                  <ChipField source="type" color={rec.color} size="small" />
                </RecordContextProvider>
              );
            }}
          />
          <FunctionField
            textAlign="right"
            label="Box"
            render={(record) => {
              let rec = { total_return: 0 };
              for (let i = 0; i < record.sale_return_items.length; i++) {
                rec.total_return +=
                  +record.sale_return_items[i].return_quantity;
              }
              return (
                <RecordContextProvider value={rec}>
                  <NumberField source="total_return" />
                </RecordContextProvider>
              );
            }}
          />
          <NumberField source="total" label="mine.label.value" />
          <TextField source="note" label="mine.label.note" emptyText="-" />
          {boolAct}
        </DatagridConfigurable>
      </List>
    );
  }, [showAside, forReport, theme]);
  return (
    <>
      {list}
      {!forReport && (
        <>
          <SaleReturnAddDialog open={openAdd} setOpen={setOpenAdd} />
          <SaleReturnEditDialog
            open={openEdit}
            setOpen={setOpenEdit}
            recordId={recordId}
          />
          <PrintHandler
            record={printRecord}
            resetPrintContent={resetPrintContent}
            Content={PrintContent}
          />
        </>
      )}
    </>
  );
};

export default SaleReturnList;
