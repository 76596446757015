import { forwardRef, Fragment } from "react";
import Box from "@mui/material/Box";
import classes from "./PrintInvoiceContent.module.css";
import Typography from "@mui/material/Typography";
import { DateField, NumberField, RecordContextProvider } from "react-admin";
import splitArrayToChunks from "../../../../utils/splitArrayToChunks";

const PrintInvoiceContent = forwardRef((props, ref) => {
  // eslint-disable-line max-len

  return (
    <Box
      component="div"
      sx={{
        display: "none",
      }}
    >
      <Box ref={ref}>
        <style type="text/css" media="print">
          {`@page {
              width: 215.9mm;
              height: 139.7mm;
              size: 8.5in 5.5in portrait;
              margin: 0;
            }
            html, body {
              width: 215.9mm;
              height: 100%;
            }
            .page-break {
              display: block;
              page-break-after: always;
            }
          `}
        </style>
        {splitArrayToChunks(props.record.sale_items, 3).map((arr, i, ori) => {
          return (
            <Fragment key={i}>
              <Box
                sx={{
                  fontSize: "12px",
                  py: 2,
                  px: 2,
                  display: "flex",
                  flexDirection: "column",
                  height: "calc(100vh - 40px)",
                  mt: i > 0 ? 2 : 0,
                }}
              >
                <Box>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography
                      sx={{
                        fontWeight: "bold",
                        mb: 0.5,
                        fontFamily: "'Source Code Pro', monospace",
                      }}
                    >
                      {props.record.company_name}
                    </Typography>
                    <Typography
                      variant="caption"
                      sx={{
                        fontSize: "10px",
                        fontFamily: "'Source Code Pro', monospace",
                      }}
                    >
                      Dibuat Oleh: {props.record.created_by + ", "}
                      <RecordContextProvider value={props.record}>
                        <DateField
                          source="created_date"
                          sx={{
                            fontFamily: "'Source Code Pro', monospace",
                            fontWeight: "normal",
                            fontSize: "inherit",
                          }}
                        ></DateField>
                      </RecordContextProvider>
                    </Typography>
                    {/* {i + 1}/{ori.length} */}
                  </div>
                  <Box
                    component="table"
                    className={classes.headerSj}
                    sx={{
                      "& th.no-wrap": {
                        whiteSpace: "nowrap",
                      },
                      "& th": {
                        verticalAlign: "top",
                      },
                    }}
                  >
                    <thead>
                      <tr>
                        <th>Kepada Yth</th>
                        <th>:</th>
                        <th style={{ fontWeight: "normal" }}>
                          {props.record.customer_name}
                        </th>
                        <th style={{ width: "5%" }}></th>
                        <th className="no-wrap">No. Surat Jalan</th>
                        <th>:</th>
                        <th
                          className="no-wrap"
                          style={{ fontWeight: "normal" }}
                        >
                          {props.record.no_sj}
                        </th>
                      </tr>
                      <tr>
                        <th>No. Customer</th>
                        <th>:</th>
                        <th style={{ fontWeight: "normal" }}>
                          {props.record.customer_code}
                        </th>
                        <th style={{ width: "5%" }}></th>
                        <th className="no-wrap">Tgl. Pengiriman</th>
                        <th>:</th>
                        <th
                          className="no-wrap"
                          style={{
                            textTransform: "uppercase",
                            fontWeight: "normal",
                          }}
                        >
                          <RecordContextProvider value={props.record}>
                            <DateField
                              source="date_sj"
                              sx={{
                                fontFamily: "'Source Code Pro', monospace",
                                fontWeight: "normal",
                                fontSize: "12px",
                              }}
                            ></DateField>
                          </RecordContextProvider>
                        </th>
                      </tr>
                      <tr>
                        <th rowSpan={2} style={{}}>
                          Alamat
                        </th>
                        <th rowSpan={2} style={{}}>
                          :
                        </th>
                        <th rowSpan={2} style={{ fontWeight: "normal" }}>
                          {props.record.address}
                        </th>
                        <th rowSpan={2} style={{ width: "5%" }}></th>
                        <th className="no-wrap">No. DO</th>
                        <th>:</th>
                        <th style={{ fontWeight: "normal" }}>
                          {props.record.no_so}
                        </th>
                      </tr>
                      <tr>
                        <th className="no-wrap" style={{ marginTop: "6px" }}>
                          Nama Angkutan
                        </th>
                        <th>:</th>
                        <th>
                          <span
                            style={{
                              textTransform: "uppercase",
                              fontWeight: "normal",
                            }}
                          >
                            {props.record.transport_name}
                          </span>
                        </th>
                      </tr>
                      <tr>
                        <th className="no-wrap" style={{ marginTop: "6px" }}>
                          Contact Person
                        </th>
                        <th>:</th>
                        <th>
                          <span
                            style={{
                              textTransform: "uppercase",
                              fontWeight: "normal",
                            }}
                          >
                            {props.record.pic}
                          </span>
                        </th>
                        <th style={{ width: "5%" }}></th>
                        <th className="no-wrap" style={{ marginTop: "6px" }}>
                          No. Polisi
                        </th>
                        <th>:</th>
                        <th>
                          <span
                            style={{
                              textTransform: "uppercase",
                              fontWeight: "normal",
                            }}
                          >
                            {props.record.transport_plate}
                          </span>
                        </th>
                      </tr>
                      <tr>
                        <th className="no-wrap" style={{ marginTop: "6px" }}>
                          Telp / Fax
                        </th>
                        <th>:</th>
                        <th>
                          <span
                            style={{
                              textTransform: "uppercase",
                              fontWeight: "normal",
                            }}
                          >
                            {props.record.address_phone}
                          </span>
                        </th>
                        <th style={{ width: "5%" }}></th>
                        <th className="no-wrap" style={{ marginTop: "6px" }}>
                          Nama Sales
                        </th>
                        <th>:</th>
                        <th>
                          <span
                            style={{
                              textTransform: "uppercase",
                              fontWeight: "normal",
                            }}
                          >
                            {props.record.salesman_name}
                          </span>
                        </th>
                      </tr>
                      <tr>
                        <th
                          className="no-wrap"
                          style={{ marginTop: "6px" }}
                        ></th>
                        <th></th>
                        <th></th>
                        <th style={{ width: "5%" }}></th>
                        <th className="no-wrap" style={{ marginTop: "6px" }}>
                          Tgl. Jatuh Tempo
                        </th>
                        <th>:</th>
                        <th>
                          <RecordContextProvider value={props.record}>
                            <DateField
                              source="due_date"
                              sx={{
                                fontFamily: "'Source Code Pro', monospace",
                                fontWeight: "normal",
                                fontSize: "12px",
                              }}
                            ></DateField>
                          </RecordContextProvider>
                        </th>
                      </tr>
                    </thead>
                  </Box>
                  <Typography
                    sx={{
                      textAlign: "center",
                      fontWeight: "bold",
                      // textDecoration: "underline",
                      fontSize: "18px",
                      mt: 0,
                      mb: 0.5,
                      lineHeight: 1,
                      fontFamily: "'Source Code Pro', monospace",
                    }}
                  >
                    INVOICE
                  </Typography>
                </Box>
                <Box sx={{ height: "100%", flex: 1 }}>
                  <Box
                    component="table"
                    className={classes.tableSj}
                    sx={{
                      width: "100%",
                      // height: "100%",
                      "& th.no-wrap": {
                        whiteSpace: "nowrap",
                      },
                      "& th": {
                        verticalAlign: "top",
                      },
                    }}
                  >
                    <thead>
                      <tr>
                        <th
                          style={{
                            width: "1%",
                            fontSize: "11px",
                            textAlign: "right",
                          }}
                        >
                          No
                        </th>
                        <th style={{ fontSize: "11px" }}>Kode Barang</th>
                        <th style={{ fontSize: "11px" }}>Nama Barang</th>
                        <th
                          style={{
                            width: "10%",
                            fontSize: "11px",
                            textAlign: "right",
                          }}
                        >
                          Q Jual (Box)
                        </th>
                        <th
                          style={{
                            width: "10%",
                            fontSize: "11px",
                            textAlign: "right",
                          }}
                        >
                          Q Retur (Box)
                        </th>
                        <th
                          style={{
                            width: "10%",
                            fontSize: "11px",
                            textAlign: "right",
                          }}
                        >
                          Q Net (Box)
                        </th>
                        <th
                          colSpan={2}
                          style={{ fontSize: "11px", textAlign: "right" }}
                        >
                          Harga Net (Rp)
                        </th>
                        <th style={{ fontSize: "11px", textAlign: "right" }}>
                          Total (Rp)
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {arr.map((obj, idx) => {
                        return (
                          <tr key={obj.product_code}>
                            <td
                              style={{
                                textAlign: "right",
                                borderTop: "none",
                                borderBottom: "none",
                                paddingTop: "0",
                                paddingBottom: "0",
                                height: "1px",
                                margin: 0,
                                fontSize: "10px",
                              }}
                            >
                              {idx + 1}
                            </td>
                            <td
                              className={classes.noWrap}
                              style={{
                                borderTop: "none",
                                borderBottom: "none",
                                paddingTop: "0",
                                paddingBottom: "0",
                                height: "1px",
                                margin: 0,
                                fontSize: "10px",
                              }}
                            >
                              {obj.product_code}
                            </td>
                            <td
                              style={{
                                borderTop: "none",
                                borderBottom: "none",
                                paddingTop: "0",
                                paddingBottom: "0",
                                height: "1px",
                                margin: 0,
                                fontSize: "10px",
                              }}
                            >
                              <p
                                style={{
                                  margin: 0,
                                  display: "-webkit-box",
                                  WebkitLineClamp: 2,
                                  WebkitBoxOrient: "vertical",
                                  textOverflow: "ellipsis",
                                  overflow: "hidden",
                                }}
                              >
                                {obj.product_name}
                              </p>
                            </td>
                            <td
                              style={{
                                textAlign: "right",
                                borderTop: "none",
                                borderBottom: "none",
                                paddingTop: "0",
                                paddingBottom: "0",
                                height: "1px",
                                margin: 0,
                              }}
                            >
                              <RecordContextProvider value={obj}>
                                <NumberField
                                  source="quantity_jual"
                                  sx={{
                                    fontFamily: "'Source Code Pro', monospace",
                                    fontWeight: "inherit",
                                    fontSize: "10px",
                                  }}
                                ></NumberField>
                              </RecordContextProvider>
                            </td>
                            <td
                              style={{
                                textAlign: "right",
                                borderTop: "none",
                                borderBottom: "none",
                                paddingTop: "0",
                                paddingBottom: "0",
                                height: "1px",
                                margin: 0,
                                fontSize: "10px",
                              }}
                            >
                              <RecordContextProvider value={obj}>
                                <NumberField
                                  source="quantity_return"
                                  sx={{
                                    fontFamily: "'Source Code Pro', monospace",
                                    fontWeight: "inherit",
                                    fontSize: "12px",
                                  }}
                                ></NumberField>
                              </RecordContextProvider>
                            </td>
                            <td
                              style={{
                                textAlign: "right",
                                borderTop: "none",
                                borderBottom: "none",
                                paddingTop: "0",
                                paddingBottom: "0",
                                height: "1px",
                                margin: 0,
                                fontSize: "10px",
                              }}
                            >
                              <RecordContextProvider value={obj}>
                                <NumberField
                                  source="quantity_net"
                                  sx={{
                                    fontFamily: "'Source Code Pro', monospace",
                                    fontWeight: "inherit",
                                    fontSize: "12px",
                                  }}
                                ></NumberField>
                              </RecordContextProvider>
                            </td>
                            <td
                              colSpan={2}
                              style={{
                                textAlign: "right",
                                borderTop: "none",
                                borderBottom: "none",
                                paddingTop: "0",
                                paddingBottom: "0",
                                height: "1px",
                                margin: 0,
                                fontSize: "10px",
                              }}
                            >
                              <RecordContextProvider value={obj}>
                                <NumberField
                                  source="sale_price_net"
                                  sx={{
                                    fontFamily: "'Source Code Pro', monospace",
                                    fontWeight: "inherit",
                                    fontSize: "12px",
                                  }}
                                ></NumberField>
                              </RecordContextProvider>
                            </td>
                            <td
                              style={{
                                textAlign: "right",
                                borderTop: "none",
                                borderBottom: "none",
                                paddingTop: "0",
                                paddingBottom: "0",
                                height: "1px",
                                margin: 0,
                                fontSize: "10px",
                              }}
                            >
                              <RecordContextProvider value={obj}>
                                <NumberField
                                  source="subtotal_price_net"
                                  sx={{
                                    fontFamily: "'Source Code Pro', monospace",
                                    fontWeight: "inherit",
                                    fontSize: "12px",
                                  }}
                                ></NumberField>
                              </RecordContextProvider>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                    <tfoot>
                      <tr>
                        <td
                          colSpan={5}
                          style={{
                            textAlign: "right",
                            verticalAlign: "baseline",
                            fontWeight: "bold",
                            borderLeft: "none",
                            borderRight: "none",
                            borderBottom: "none",
                            paddingTop: 0,
                            paddingBottom: 0,
                          }}
                        >
                          Total Kuantitas :
                        </td>
                        <td
                          style={{
                            textAlign: "right",
                            verticalAlign: "baseline",
                            fontWeight: "bold",
                            borderLeft: "none",
                            borderRight: "none",
                            borderBottom: "none",
                            paddingTop: 0,
                            paddingBottom: 0,
                          }}
                        >
                          <RecordContextProvider value={props.record}>
                            <NumberField
                              source="total_quantity"
                              sx={{
                                fontFamily: "'Source Code Pro', monospace",
                                fontWeight: "inherit",
                                fontSize: "12px",
                              }}
                            ></NumberField>
                          </RecordContextProvider>
                        </td>
                        <td
                          style={{
                            verticalAlign: "baseline",
                            fontWeight: "bold",
                            borderLeft: "none",
                            borderRight: "none",
                            borderBottom: "none",
                            paddingTop: 0,
                            paddingBottom: 0,
                          }}
                        >
                          Sub Total
                        </td>
                        <td
                          style={{
                            textAlign: "center",
                            fontWeight: "bold",
                            borderLeft: "none",
                            borderRight: "none",
                            borderBottom: "none",
                            paddingTop: 0,
                            paddingBottom: 0,
                          }}
                        >
                          :
                        </td>
                        <td
                          style={{
                            textAlign: "right",
                            verticalAlign: "baseline",
                            fontWeight: "bold",
                            borderLeft: "none",
                            borderRight: "none",
                            borderBottom: "none",
                            paddingTop: 0,
                            paddingBottom: 0,
                          }}
                        >
                          <RecordContextProvider value={props.record}>
                            <NumberField
                              source="subtotal"
                              sx={{
                                fontFamily: "'Source Code Pro', monospace",
                                fontWeight: "inherit",
                                fontSize: "12px",
                              }}
                            ></NumberField>
                          </RecordContextProvider>
                        </td>
                      </tr>
                      <tr>
                        <td
                          colSpan={6}
                          style={{
                            border: "none",
                            paddingTop: 0,
                            paddingBottom: 0,
                          }}
                        ></td>
                        <td
                          style={{
                            verticalAlign: "baseline",
                            fontWeight: "bold",
                            border: "none",
                            paddingTop: 0,
                            paddingBottom: 0,
                          }}
                        >
                          PreReceived
                        </td>
                        <td
                          style={{
                            textAlign: "center",
                            textAlign: "center",
                            fontWeight: "bold",
                            border: "none",
                            paddingTop: 0,
                            paddingBottom: 0,
                          }}
                        >
                          :
                        </td>
                        <td
                          style={{
                            textAlign: "right",
                            verticalAlign: "baseline",
                            fontWeight: "bold",
                            border: "none",
                            paddingTop: 0,
                            paddingBottom: 0,
                          }}
                        >
                          <RecordContextProvider value={props.record}>
                            <NumberField
                              source="prereceived"
                              sx={{
                                fontFamily: "'Source Code Pro', monospace",
                                fontWeight: "inherit",
                                fontSize: "12px",
                              }}
                            ></NumberField>
                          </RecordContextProvider>
                        </td>
                      </tr>
                      <tr>
                        <td
                          colSpan={6}
                          style={{
                            border: "none",
                            paddingTop: 0,
                            paddingBottom: 0,
                          }}
                        ></td>
                        <td
                          style={{
                            verticalAlign: "baseline",
                            fontWeight: "bold",
                            border: "none",
                            paddingTop: 0,
                            paddingBottom: 0,
                          }}
                        >
                          Cash Discount
                        </td>
                        <td
                          style={{
                            textAlign: "center",
                            verticalAlign: "baseline",
                            fontWeight: "bold",
                            border: "none",
                            paddingTop: 0,
                            paddingBottom: 0,
                          }}
                        >
                          :
                        </td>
                        <td
                          style={{
                            textAlign: "right",
                            verticalAlign: "baseline",
                            fontWeight: "bold",
                            border: "none",
                            paddingTop: 0,
                            paddingBottom: 0,
                          }}
                        >
                          <RecordContextProvider value={props.record}>
                            <NumberField
                              source="cash_discount"
                              sx={{
                                fontFamily: "'Source Code Pro', monospace",
                                fontWeight: "inherit",
                                fontSize: "12px",
                              }}
                            ></NumberField>
                          </RecordContextProvider>
                        </td>
                      </tr>
                      <tr>
                        <td
                          colSpan={6}
                          style={{
                            border: "none",
                            paddingTop: 0,
                            paddingBottom: 0,
                          }}
                        ></td>
                        <td
                          style={{
                            verticalAlign: "baseline",
                            fontWeight: "bold",
                            borderBottom: "none",
                            borderLeft: "none",
                            borderRight: "none",
                            paddingTop: 0,
                            paddingBottom: 0,
                          }}
                        >
                          Total
                        </td>
                        <td
                          style={{
                            textAlign: "center",
                            verticalAlign: "baseline",
                            fontWeight: "bold",
                            borderBottom: "none",
                            borderLeft: "none",
                            borderRight: "none",
                            paddingTop: 0,
                            paddingBottom: 0,
                          }}
                        >
                          :
                        </td>
                        <td
                          style={{
                            textAlign: "right",
                            verticalAlign: "baseline",
                            fontWeight: "bold",
                            borderBottom: "none",
                            borderLeft: "none",
                            borderRight: "none",
                            paddingTop: 0,
                            paddingBottom: 0,
                          }}
                        >
                          <RecordContextProvider value={props.record}>
                            <NumberField
                              source="total"
                              sx={{
                                fontFamily: "'Source Code Pro', monospace",
                                fontWeight: "inherit",
                                fontSize: "12px",
                              }}
                            ></NumberField>
                          </RecordContextProvider>
                        </td>
                      </tr>
                    </tfoot>
                  </Box>
                </Box>
                <Typography
                  sx={{
                    mt: "auto",
                    mb: 1.5,
                    fontSize: "10px",
                    textTransform: "capitalize",
                    fontFamily: "'Source Code Pro', monospace",
                  }}
                >
                  {"Terbilang: " + props.record.total_in_words + " Rupiah"}
                </Typography>

                <Box
                  component="table"
                  className={classes.tableSj}
                  sx={{
                    width: "100%",
                    height: "100px",
                    "& th.no-wrap": {
                      whiteSpace: "nowrap",
                    },
                    "& th": {
                      border: "none",
                      verticalAlign: "top",
                    },
                    "& td": {
                      border: "none",
                    },
                  }}
                >
                  <thead>
                    <tr>
                      <th
                        style={{
                          padding: "3px 0",
                          height: "10px",
                        }}
                      >
                        CATATAN :
                      </th>
                      <th
                        style={{
                          textAlign: "center",
                          padding: "3px 0",
                          height: "10px",
                        }}
                      >
                        Hormat Kami,
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td
                        style={{
                          fontSize: "10px",
                          lineHeight: 1,
                        }}
                      >
                        <p style={{ paddingBottom: "6px" }}>
                          - Pembayaran diakui setelah masuk rekening perusahaan,
                          pembayaran selain dengan transfer ke rekening
                          perusahaan diluar tanggung jawab kami
                        </p>
                        <p>
                          - Pembayaran dengan cek / giro baru dianggap sah
                          setelah warkat ybs dapat diuangkan / dikreditkan ke
                          bank kami.
                        </p>
                        <p className="page-no" style={{ marginTop: "8px" }}>
                          page {i + 1}/{ori.length}
                        </p>
                      </td>
                      <td
                        style={{
                          textAlign: "center",
                          width: "20%",
                          verticalAlign: "bottom",
                        }}
                      >
                        <Typography variant="caption">
                          ( ...................... )
                        </Typography>
                      </td>
                    </tr>
                  </tbody>
                </Box>
              </Box>
              <div className="page-break" />
            </Fragment>
          );
        })}
      </Box>
    </Box>
  );
});
export default PrintInvoiceContent;
