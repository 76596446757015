import { useMemo } from "react";
import {
  List,
  TextField,
  DatagridConfigurable,
  useTheme,
  NumberField,
  usePermissions,
  FunctionField,
  RecordContextProvider,
  ChipField,
} from "react-admin";
import getFirstAndLastDateOfCurrentMonth from "../../utils/firstLastDate";
import InventoryCardAsideList from "../../components/inventoryCards/aside/InventoryCardAsideList";
import DownloadAction from "../../components/tableListActions/DownloadAction";
import inventoryAgingFilterList from "../../components/inventoryAgings/filter/inventoryAgingFilterList";

const [firstDate, lastDate] = getFirstAndLastDateOfCurrentMonth();

const InventoryAgingList = () => {
  const { permissions } = usePermissions();
  const [theme] = useTheme();

  const list = useMemo(
    () => (
      <List
        storeKey={false} // Disabling Parameters Persistence
        title="mine.list_title.inventory_aging"
        empty={false}
        // aside={<InventoryCardAsideList />}
        actions={<DownloadAction />}
        filters={inventoryAgingFilterList(permissions)}
        filterDefaultValues={{
          start_date: firstDate,
          end_date: lastDate,
          cut_off: lastDate,
        }}
        queryOptions={{ meta: { sort: ["Product.code ASC"] } }} // overide default sort
        sx={{
          flexDirection: "column",
        }}
      >
        <DatagridConfigurable
          bulkActionButtons={false}
          // rowClick={rowClickHandler}
          // expand={<InventoryCardExpandList />}
          sx={{
            "& .RaDatagrid-expandedPanel > td": {
              p: 0,
              bgcolor: theme !== "dark" ? "#E7EBF0" : "grey.800",
            },
          }}
        >
          <TextField source="product.code" label="mine.label.product_code" />
          <FunctionField
            label="mine.label.brand"
            render={(record) => {
              return (
                <RecordContextProvider
                  value={{ brand: record.product.code.substr(0, 1) }}
                >
                  <TextField source="brand" />
                </RecordContextProvider>
              );
            }}
          />
          <FunctionField
            label="mine.label.size"
            render={(record) => {
              return (
                <RecordContextProvider
                  value={{ size: record.product.code.substr(1, 2) }}
                >
                  <TextField source="size" />
                </RecordContextProvider>
              );
            }}
          />
          <TextField source="product.kind.name" label="mine.label.kind" />
          <NumberField
            source="remaining_quantity"
            label="mine.label.quantity"
          />
          <NumberField source="age" label="mine.label.age" />
          <FunctionField
            label="Status"
            textAlign="center"
            render={(record) => {
              let rec = { status: record.status, color: "default" };
              if (record.status.includes(">")) {
                rec.color = "error";
              }
              return (
                <RecordContextProvider value={rec}>
                  <ChipField source="status" color={rec.color} size="small" />
                </RecordContextProvider>
              );
            }}
          />
        </DatagridConfigurable>
      </List>
    ),
    [theme, permissions]
  );

  return list;
};

export default InventoryAgingList;
