import { Menu, usePermissions, useTranslate } from "react-admin";
import DatasetIcon from "@mui/icons-material/Dataset";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import PointOfSaleIcon from "@mui/icons-material/PointOfSale";
import MoneyOffIcon from "@mui/icons-material/MoneyOff";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import PaymentIcon from "@mui/icons-material/Payment";
import SubMenu from "./SubMenu";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import RecyclingIcon from "@mui/icons-material/Recycling";
import Divider from "@mui/material/Divider";
import ReceiptIcon from "@mui/icons-material/Receipt";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import Inventory2Icon from "@mui/icons-material/Inventory2";

const MyMenu = () => {
  const t = useTranslate();
  // const [open, setOpen] = useState(true);
  const { permissions } = usePermissions();
  // const resources = useResourceDefinitions();

  const isAdministrator = permissions && permissions.administrator;

  // const handleClick = () => {
  //   setOpen(!open);
  // };
  return (
    permissions && (
      <Menu
        sx={{
          "& a .RaMenuItemLink-icon": {
            minWidth: "41px",
          },
        }}
      >
        <Menu.DashboardItem />
        {/* {Object.keys(resources).map((name) => (
        <Menu.ResourceItem key={name} name={name} />
      ))} */}
        <Divider
          variant="middle"
          textAlign="left"
          sx={{
            my: 1,
            textTransform: "uppercase",
            // color: "#9055fd"
          }}
        >
          {t("mine.label.transaction")}
        </Divider>
        {(isAdministrator ||
          permissions.list_purchase_order ||
          permissions.list_purchase ||
          permissions.list_purchase_return) && (
          <SubMenu
            url="purchase"
            primaryText="mine.expand_menu.purchase"
            leftIcon={<ShoppingCartIcon />}
          >
            {(isAdministrator || permissions.list_purchase_order) && (
              <Menu.ResourceItem name="purchase-orders" />
            )}
            {(isAdministrator || permissions.list_purchase) && (
              <Menu.ResourceItem name="purchases" />
            )}
            {(isAdministrator || permissions.list_purchase_return) && (
              <Menu.ResourceItem name="purchase-returns" />
            )}
          </SubMenu>
        )}

        {(isAdministrator ||
          permissions.list_sale_order ||
          permissions.list_sale ||
          permissions.list_sale_return) && (
          <SubMenu
            url={["sale-orders", "sales", "sale-returns"]}
            primaryText="mine.expand_menu.sale"
            leftIcon={<PointOfSaleIcon />}
          >
            {(isAdministrator || permissions.list_sale_order) && (
              <Menu.ResourceItem name="sale-orders" />
            )}
            {(isAdministrator || permissions.list_sale) && (
              <Menu.ResourceItem name="sales" />
            )}
            {(isAdministrator || permissions.list_sale_return) && (
              <Menu.ResourceItem name="sale-returns" />
            )}
          </SubMenu>
        )}

        {(isAdministrator ||
          permissions.list_factory_debt_payment ||
          permissions.list_factory_debt_correction) && (
          <SubMenu
            url="factory-debt"
            primaryText="mine.expand_menu.factory_debt"
            leftIcon={<PaymentIcon />}
          >
            {(isAdministrator || permissions.list_factory_debt_payment) && (
              <Menu.ResourceItem name="factory-debt-payments" />
            )}
            {(isAdministrator || permissions.list_factory_debt_correction) && (
              <Menu.ResourceItem name="factory-debt-corrections" />
            )}
          </SubMenu>
        )}

        {(isAdministrator ||
          permissions.list_customer_debt_payment ||
          permissions.list_customer_debt_correction) && (
          <SubMenu
            url={["customer-debt-payments", "customer-debt-corrections"]}
            primaryText="mine.expand_menu.customer_debt"
            leftIcon={<AccountBalanceWalletIcon />}
          >
            {(isAdministrator || permissions.list_customer_debt_payment) && (
              <Menu.ResourceItem name="customer-debt-payments" />
            )}
            {(isAdministrator || permissions.list_customer_debt_correction) && (
              <Menu.ResourceItem name="customer-debt-corrections" />
            )}
          </SubMenu>
        )}

        {(isAdministrator || permissions.list_check_cashing) && (
          <Menu.ResourceItem name="check-cashing" />
        )}

        {(isAdministrator || permissions.list_journal) && (
          <Menu.ResourceItem name="journals" />
        )}

        {(isAdministrator ||
          permissions.list_bad_debt ||
          permissions.list_bad_debt_installment ||
          permissions.list_bad_debt_correction ||
          permissions.list_bad_debt_check_cashing) && (
          <SubMenu
            url="bad-debt"
            primaryText="mine.expand_menu.bad_debt"
            leftIcon={<MoneyOffIcon />}
          >
            {(isAdministrator || permissions.list_bad_debt) && (
              <Menu.ResourceItem name="bad-debts" />
            )}
            {(isAdministrator || permissions.list_bad_debt_installment) && (
              <Menu.ResourceItem name="bad-debt-installments" />
            )}
            {(isAdministrator || permissions.list_bad_debt_correction) && (
              <Menu.ResourceItem name="bad-debt-corrections" />
            )}
            {(isAdministrator || permissions.list_bad_debt_check_cashing) && (
              <Menu.ResourceItem name="bad-debt-check-cashing" />
            )}
          </SubMenu>
        )}

        {(isAdministrator ||
          permissions.list_mutation_repacking ||
          permissions.list_mutation_inventory_write_off) && (
          <SubMenu
            url="mutation"
            primaryText="mine.expand_menu.mutation"
            leftIcon={<RecyclingIcon />}
          >
            {(isAdministrator || permissions.list_mutation_repacking) && (
              <Menu.ResourceItem name="mutation-repacking" />
            )}
            {(isAdministrator ||
              permissions.list_mutation_inventory_write_off) && (
              <Menu.ResourceItem name="mutation-inventory-write-offs" />
            )}
            {/* <Menu.ResourceItem name="mutation-change-goods-codes" />
        <Menu.ResourceItem name="mutation-change-locations" /> */}
          </SubMenu>
        )}

        {(isAdministrator || permissions.list_inventory_pending) && (
          <Menu.ResourceItem name="inventory-pendings" />
        )}

        {(isAdministrator || permissions.list_close_book) && (
          <Menu.ResourceItem name="close-books" />
        )}

        <Divider
          variant="middle"
          textAlign="left"
          sx={{
            my: 1,
            textTransform: "uppercase",
            // color: "#9055fd"
          }}
        >
          {t("mine.label.report")}
        </Divider>

        {(isAdministrator || permissions.list_balance_sheet) && (
          <Menu.ResourceItem name="balance-sheets" />
        )}

        {(isAdministrator || permissions.list_income_statement) && (
          <Menu.ResourceItem name="income-statements" />
        )}

        {(isAdministrator ||
          permissions.list_general_ledger ||
          permissions.list_all_transaction_journal) && (
          <SubMenu
            url={["general-ledgers", "all-transaction-journals"]}
            primaryText="mine.expand_menu.general_ledger"
            leftIcon={<AccountBalanceIcon />}
          >
            {(isAdministrator || permissions.list_general_ledger) && (
              <Menu.ResourceItem name="general-ledgers" />
            )}
            {(isAdministrator || permissions.list_all_transaction_journal) && (
              <Menu.ResourceItem name="all-transaction-journals" />
            )}
          </SubMenu>
        )}

        {(isAdministrator ||
          permissions.list_purchase_net_card ||
          permissions.list_purchase_card ||
          permissions.list_purchase_return_card) && (
          <SubMenu
            url={[
              "purchase-net-cards",
              "purchase-cards",
              "purchase-return-cards",
            ]}
            primaryText="mine.expand_menu.purchase"
            leftIcon={<ShoppingCartIcon />}
          >
            {(isAdministrator || permissions.list_purchase_net_card) && (
              <Menu.ResourceItem name="purchase-net-cards" />
            )}
            {(isAdministrator || permissions.list_purchase_card) && (
              <Menu.ResourceItem name="purchase-cards" />
            )}
            {(isAdministrator || permissions.list_purchase_return_card) && (
              <Menu.ResourceItem name="purchase-return-cards" />
            )}
          </SubMenu>
        )}

        {(isAdministrator ||
          permissions.list_sale_net_card ||
          permissions.list_sale_card ||
          permissions.list_sale_return_card) && (
          <SubMenu
            url={["sale-net-cards", "sale-cards", "sale-return-cards"]}
            primaryText="mine.expand_menu.sale"
            leftIcon={<PointOfSaleIcon />}
          >
            {(isAdministrator || permissions.list_sale_net_card) && (
              <Menu.ResourceItem name="sale-net-cards" />
            )}
            {(isAdministrator || permissions.list_sale_card) && (
              <Menu.ResourceItem name="sale-cards" />
            )}
            {(isAdministrator || permissions.list_sale_return_card) && (
              <Menu.ResourceItem name="sale-return-cards" />
            )}
          </SubMenu>
        )}

        {(isAdministrator ||
          permissions.list_account_receivable_pcm ||
          permissions.list_account_receivable_card ||
          permissions.list_account_receivable_aging ||
          permissions.list_account_receivable_history) && (
          <SubMenu
            url={[
              "account-receivable-pcms",
              "account-receivable-cards",
              "account-receivable-agings",
              "account-receivable-histories",
            ]}
            primaryText="mine.expand_menu.account_receivables"
            leftIcon={<ReceiptIcon />}
          >
            {(isAdministrator || permissions.list_account_receivable_pcm) && (
              <Menu.ResourceItem name="account-receivable-pcms" />
            )}
            {(isAdministrator || permissions.list_account_receivable_card) && (
              <Menu.ResourceItem name="account-receivable-cards" />
            )}
            {(isAdministrator || permissions.list_account_receivable_aging) && (
              <Menu.ResourceItem name="account-receivable-agings" />
            )}
            {(isAdministrator ||
              permissions.list_account_receivable_history) && (
              <Menu.ResourceItem name="account-receivable-histories" />
            )}
          </SubMenu>
        )}

        {(isAdministrator || permissions.list_account_payable_card) && (
          <SubMenu
            url={["account-payable-cards"]}
            primaryText="mine.expand_menu.account_payables"
            leftIcon={<ReceiptLongIcon />}
          >
            {(isAdministrator || permissions.list_account_payable_card) && (
              <Menu.ResourceItem name="account-payable-cards" />
            )}
          </SubMenu>
        )}

        {(isAdministrator ||
          permissions.list_inventory_card ||
          permissions.list_inventory_aging) && (
          <SubMenu
            url={["inventory-cards", "inventory-agings"]}
            primaryText="mine.expand_menu.inventories"
            leftIcon={<Inventory2Icon />}
          >
            {(isAdministrator || permissions.list_inventory_card) && (
              <Menu.ResourceItem name="inventory-cards" />
            )}
            {(isAdministrator || permissions.list_inventory_aging) && (
              <Menu.ResourceItem name="inventory-agings" />
            )}
          </SubMenu>
        )}
        {/* <SubMenu
        url="report"
        primaryText="mine.expand_menu.report"
        leftIcon={<ChromeReaderModeIcon />}
      ></SubMenu> */}
        {(isAdministrator || permissions.list_activity) && (
          <Menu.ResourceItem name="activities" />
        )}

        <Divider
          variant="middle"
          textAlign="left"
          sx={{
            my: 1,
            textTransform: "uppercase",
            // color: "#9055fd"
          }}
        >
          Master Data
        </Divider>

        {(isAdministrator || permissions.list_company) && (
          <Menu.ResourceItem name="companies" />
        )}

        {isAdministrator && (
          <>
            <Menu.ResourceItem name="users" />
            <Menu.ResourceItem name="roles" />
            <Menu.ResourceItem name="permissions" />
          </>
        )}

        {(isAdministrator || permissions.list_coa) && (
          <Menu.ResourceItem name="coa" />
        )}

        {(isAdministrator || permissions.list_sales_area) && (
          <Menu.ResourceItem url="sales-areas" name="sales-areas" />
        )}

        {(isAdministrator || permissions.list_customer_type) && (
          <Menu.ResourceItem name="customer-types" />
        )}

        {(isAdministrator || permissions.list_customer) && (
          <Menu.ResourceItem name="customers" />
        )}

        {(isAdministrator || permissions.list_supplier) && (
          <Menu.ResourceItem name="suppliers" />
        )}

        {(isAdministrator ||
          permissions.list_brand ||
          permissions.list_size ||
          permissions.list_motif ||
          permissions.list_kind ||
          permissions.list_density ||
          permissions.list_color ||
          permissions.list_quality) && (
          <SubMenu
            url={[
              "brands",
              "sizes",
              "motifs",
              "kinds",
              "densities",
              "colors",
              "qualities",
            ]}
            primaryText="mine.expand_menu.product_code"
            leftIcon={<DatasetIcon />}
          >
            {(isAdministrator || permissions.list_brand) && (
              <Menu.ResourceItem name="brands" />
            )}
            {(isAdministrator || permissions.list_size) && (
              <Menu.ResourceItem name="sizes" />
            )}
            {(isAdministrator || permissions.list_motif) && (
              <Menu.ResourceItem name="motifs" />
            )}
            {(isAdministrator || permissions.list_kind) && (
              <Menu.ResourceItem name="kinds" />
            )}
            {(isAdministrator || permissions.list_density) && (
              <Menu.ResourceItem name="densities" />
            )}
            {(isAdministrator || permissions.list_color) && (
              <Menu.ResourceItem name="colors" />
            )}
            {(isAdministrator || permissions.list_quality) && (
              <Menu.ResourceItem name="qualities" />
            )}
          </SubMenu>
        )}

        {(isAdministrator || permissions.list_product) && (
          <Menu.ResourceItem name="products" />
        )}

        {(isAdministrator || permissions.list_price) && (
          <Menu.ResourceItem name="prices" />
        )}

        {(isAdministrator || permissions.list_price_limit) && (
          <Menu.ResourceItem name="price-limits" />
        )}

        {(isAdministrator || permissions.list_brand_price_addition) && (
          <Menu.ResourceItem name="brand-price-additions" />
        )}

        {(isAdministrator || permissions.list_size_price_addition) && (
          <Menu.ResourceItem name="size-price-additions" />
        )}

        {(isAdministrator || permissions.list_promotion) && (
          <Menu.ResourceItem name="promotions" />
        )}

        {(isAdministrator || permissions.list_salesman) && (
          <Menu.ResourceItem name="salesmen" />
        )}

        {(isAdministrator || permissions.list_salesman_target) && (
          <Menu.ResourceItem name="salesman-targets" />
        )}

        {(isAdministrator || permissions.list_warehouse) && (
          <Menu.ResourceItem name="warehouses" />
        )}

        {(isAdministrator || permissions.list_transport) && (
          <Menu.ResourceItem name="transports" />
        )}

        {(isAdministrator || permissions.list_customer_debt_max_day) && (
          <Menu.ResourceItem name="customer-debt-max-days" />
        )}
      </Menu>
    )
  );
};
export default MyMenu;
