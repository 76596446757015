import { forwardRef, Fragment } from "react";
import Box from "@mui/material/Box";
import classes from "./PrintContent.module.css";
import Typography from "@mui/material/Typography";
import {
  DateField,
  NumberField,
  RecordContextProvider,
  // usePermissions,
} from "react-admin";
import splitArrayToChunks from "../../../utils/splitArrayToChunks";

const PrintContent = forwardRef((props, ref) => {
  // eslint-disable-line max-len
  // const { permissions, isLoading } = usePermissions();
  let paymentType = "";
  if (props.record.type === 1) {
    paymentType = "Transfer";
  } else if (props.record.type === 2) {
    paymentType = "Cash";
  } else if (props.record.type === 3) {
    paymentType = "Giro";
  }

  return (
    <Box
      component="div"
      sx={{
        display: "none",
      }}
    >
      <Box ref={ref}>
        <style type="text/css" media="print">
          {`@page {
              width: 215.9mm;
              height: 139.7mm;
              size: 8.5in 5.5in portrait;
              margin: 0;
            }
            html, body {
              width: 215.9mm;
              height: 100%;
            }
            .page-break {
              display: block;
              page-break-after: always;
            }
          `}
        </style>
        {splitArrayToChunks(props.record.customer_debt_payment_items, 5).map(
          (arr, i, ori) => {
            // TODO semua print content strukturnya perlu ikutin ini
            return (
              <Fragment key={i}>
                <Box
                  sx={
                    i > 0
                      ? {
                          mt: 2,
                          fontSize: "12px",
                          py: 2,
                          px: 2,
                          display: "flex",
                          flexDirection: "column",
                          height: "calc(100vh - 40px)",
                          fontFamily: "'Source Code Pro', monospace",
                        }
                      : {
                          fontSize: "12px",
                          py: 2,
                          px: 2,
                          display: "flex",
                          flexDirection: "column",
                          height: "calc(100vh - 40px)",
                          fontFamily: "'Source Code Pro', monospace",
                        }
                  }
                >
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      mb: 2,
                      textTransform: "uppercase",
                      borderBottom: "1px solid #000",
                      textAlign: "center",
                      fontFamily: "inherit",
                    }}
                  >
                    bukti pembayaran piutang
                  </Typography>
                  <table className={classes.headerSj}>
                    <thead>
                      <tr>
                        <th style={{ width: "15%", verticalAlign: "top" }}>
                          Diterima dari
                        </th>
                        <th style={{ verticalAlign: "top" }}>:</th>
                        <th style={{ width: "60%", verticalAlign: "top" }}>
                          {props.record.customer.name}
                        </th>
                        <th style={{ width: "10%", verticalAlign: "top" }}>
                          Tanggal
                        </th>
                        <th style={{ verticalAlign: "top" }}>:</th>
                        <th style={{ width: "15%", verticalAlign: "top" }}>
                          <RecordContextProvider value={props.record}>
                            <DateField
                              source="date"
                              sx={{
                                fontWeight: "inherit",
                                fontSize: "12px",
                                fontFamily: "inherit",
                              }}
                            ></DateField>
                          </RecordContextProvider>
                        </th>
                      </tr>
                      <tr>
                        <th style={{ verticalAlign: "top" }}>Tipe Bayar</th>
                        <th style={{ verticalAlign: "top" }}>:</th>
                        <th style={{ verticalAlign: "top" }}>{paymentType}</th>
                        <th style={{ verticalAlign: "top" }}>No. Bukti</th>
                        <th style={{ verticalAlign: "top" }}>:</th>
                        <th style={{ verticalAlign: "top", width: "100px" }}>
                          {props.record.code}
                        </th>
                      </tr>
                    </thead>
                  </table>
                  <Typography
                    variant="caption"
                    sx={{
                      mt: 2,
                      mb: 1,
                      fontFamily: "inherit",
                    }}
                  >
                    Keterangan: {props.record.note}
                  </Typography>
                  <table className={classes.tableSj}>
                    <thead>
                      <tr>
                        <th>No. Transaksi</th>
                        <th>Tgl. Transaksi</th>
                        <th>Tgl. Jatuh Tempo</th>
                        <th style={{ textAlign: "right" }}>Jumlah Tagihan</th>
                        <th style={{ textAlign: "right" }}>Jumlah Bayar</th>
                      </tr>
                    </thead>
                    <tbody>
                      {arr.map((obj, idx) => {
                        return (
                          <tr key={obj.id}>
                            <td>{obj.sale.code}</td>
                            <td>
                              <RecordContextProvider value={obj.sale}>
                                <DateField
                                  source="date"
                                  sx={{
                                    fontWeight: "inherit",
                                    fontSize: "12px",
                                    fontFamily: "inherit",
                                  }}
                                ></DateField>
                              </RecordContextProvider>
                            </td>
                            <td>
                              <RecordContextProvider
                                value={obj.sale.customer_debt}
                              >
                                <DateField
                                  source="due_date"
                                  sx={{
                                    fontWeight: "inherit",
                                    fontSize: "12px",
                                    fontFamily: "inherit",
                                  }}
                                ></DateField>
                              </RecordContextProvider>
                            </td>
                            <td style={{ textAlign: "right" }}>
                              <RecordContextProvider
                                value={obj.sale.customer_debt}
                              >
                                <NumberField
                                  source="total"
                                  sx={{
                                    fontWeight: "inherit",
                                    fontSize: "12px",
                                    fontFamily: "inherit",
                                  }}
                                ></NumberField>
                              </RecordContextProvider>
                            </td>
                            <td style={{ textAlign: "right" }}>
                              <RecordContextProvider value={obj}>
                                <NumberField
                                  source="amount"
                                  sx={{
                                    fontWeight: "inherit",
                                    fontSize: "12px",
                                    fontFamily: "inherit",
                                  }}
                                ></NumberField>
                              </RecordContextProvider>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                    <tfoot>
                      <tr>
                        <td
                          colSpan={4}
                          style={{
                            textAlign: "right",
                            verticalAlign: "baseline",
                            fontWeight: "bold",
                            borderRight: "none",
                          }}
                        >
                          Total:
                        </td>
                        <td style={{ textAlign: "right" }}>
                          <RecordContextProvider value={props.record}>
                            <NumberField
                              source="total"
                              sx={{
                                fontWeight: "inherit",
                                fontSize: "12px",
                                fontFamily: "inherit",
                              }}
                            ></NumberField>
                          </RecordContextProvider>
                        </td>
                      </tr>
                    </tfoot>
                  </table>
                  <Typography
                    variant="caption"
                    sx={{
                      mt: "auto",
                      textTransform: "capitalize",
                      fontFamily: "inherit",
                    }}
                  >
                    {"Terbilang: # " +
                      props.record.total_in_words +
                      " Rupiah #"}
                  </Typography>
                  <table
                    style={{ marginTop: "8px" }}
                    className={classes.tableACC}
                  >
                    <thead>
                      <tr>
                        <th>Mengetahui</th>
                        <th colSpan={2}>Pelaksana</th>
                      </tr>
                      <tr>
                        <th
                          style={{
                            textTransform: "uppercase",
                            width: "33.33%",
                          }}
                        >
                          Kepala Depo
                        </th>
                        <th
                          style={{
                            textTransform: "uppercase",
                            width: "33.33%",
                          }}
                        >
                          Akunting
                        </th>
                        <th
                          style={{
                            textTransform: "uppercase",
                            width: "33.33%",
                          }}
                        >
                          Finance
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td></td>
                        <td></td>
                        <td style={{ paddingTop: "60px" }}></td>
                      </tr>
                    </tbody>
                  </table>
                </Box>
                <div className="page-break" />
              </Fragment>
            );
          }
        )}
      </Box>
    </Box>
  );
});
export default PrintContent;
