import {
  DateField,
  useRecordContext,
  RecordContextProvider,
  TabbedShowLayout,
  NumberField,
  // WithRecord,
  useTranslate,
} from "react-admin";
import Box from "@mui/material/Box";

const CustomerExpandList = () => {
  const record = useRecordContext();
  const t = useTranslate();
  return (
    <TabbedShowLayout
      syncWithLocation={false}
      sx={{
        m: 5,
        backgroundClip: "padding-box",
        boxShadow: (theme) => {
          return theme.components.MuiPaper.styleOverrides.elevation1.boxShadow;
        },
        borderRadius: "4px",
        bgcolor: "background.paper",
      }}
    >
      <TabbedShowLayout.Tab label="mine.label.address">
        <Box
          component="table"
          sx={{
            width: "100%",
            // bgcolor: "rgba(0,0,0,.04)",
            fontSize: "smaller",
            borderCollapse: "collapse",
            "& th, td": {
              padding: "6px 8px",
              border: "1px solid #dddddd",
            },
          }}
        >
          <thead>
            <tr>
              <th>No.</th>
              <th>{t("mine.label.name")}</th>
              <th>Phone</th>
              <th>Fax</th>
              <th>{t("mine.label.address")}</th>
            </tr>
          </thead>
          <tbody>
            {record.customer_addresses.map((addr, idx) => {
              return (
                <tr key={addr.id}>
                  <td>{idx + 1}</td>
                  <td>{addr.location_name}</td>
                  <td>{addr.phone}</td>
                  <td>{addr.fax}</td>
                  <td>
                    {addr.detail +
                      ", " +
                      addr.city.name +
                      ", " +
                      addr.province.name +
                      ", " +
                      addr.postal_code}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Box>
      </TabbedShowLayout.Tab>
      {record.customer_debts && record.customer_debts.length ? (
        <TabbedShowLayout.Tab label="mine.label.debt">
          <Box
            component="table"
            sx={{
              width: "100%",
              // bgcolor: "rgba(0,0,0,.04)",
              fontSize: "smaller",
              borderCollapse: "collapse",
              "& th, td": {
                padding: "6px 8px",
                border: "1px solid #dddddd",
              },
              "& th": {
                textTransform: "capitalize",
              },
            }}
          >
            <thead>
              <tr>
                <th>{t("mine.label.sale_code")}</th>
                <th>Total</th>
                <th>{t("mine.label.remain")}</th>
                <th>{t("mine.label.transaction_date")}</th>
                <th>{t("mine.label.due_date")}</th>
              </tr>
            </thead>
            <tbody>
              {record.customer_debts.map((item, idx) => {
                return (
                  <tr key={item.id}>
                    <td>{item.sale.code}</td>
                    <td>
                      <RecordContextProvider value={item}>
                        <NumberField
                          source="total"
                          sx={{
                            fontSize: "inherit",
                          }}
                        />
                      </RecordContextProvider>
                    </td>
                    <td>
                      <RecordContextProvider value={item}>
                        <NumberField
                          source="remain"
                          sx={{
                            fontSize: "inherit",
                          }}
                        />
                      </RecordContextProvider>
                    </td>
                    <td>
                      <RecordContextProvider value={item.sale}>
                        <DateField
                          source="date"
                          sx={{
                            fontSize: "inherit",
                          }}
                        />
                      </RecordContextProvider>
                    </td>
                    <td>
                      <RecordContextProvider value={item}>
                        <DateField
                          source="due_date"
                          sx={{
                            fontSize: "inherit",
                          }}
                        />
                      </RecordContextProvider>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Box>
        </TabbedShowLayout.Tab>
      ) : null}
      {record.sale_orders && record.sale_orders.length ? (
        <TabbedShowLayout.Tab label="Pending">
          <Box
            component="table"
            sx={{
              width: "100%",
              // bgcolor: "rgba(0,0,0,.04)",
              fontSize: "smaller",
              borderCollapse: "collapse",
              "& th, td": {
                padding: "6px 8px",
                border: "1px solid #dddddd",
              },
            }}
          >
            <thead>
              <tr>
                <th>No.</th>
                <th>Sale Order Code</th>
                <th>Product Code</th>
                <th>Pending</th>
                <th>Created At</th>
              </tr>
            </thead>
            <tbody>
              {record.sale_orders.map((sale_order, idx) => {
                return sale_order.sale_order_items.map((soi, ix) => {
                  if (!soi.inventory_pending.id) return null;
                  return (
                    <tr key={soi.inventory_pending.id}>
                      <td>{ix + 1}</td>
                      <td>{sale_order.code}</td>
                      <td>{soi.inventory_pending.product.code}</td>
                      <td>
                        <RecordContextProvider value={soi.inventory_pending}>
                          <NumberField
                            source="pending"
                            sx={{
                              fontSize: "inherit",
                            }}
                          />
                        </RecordContextProvider>
                      </td>
                      <td>
                        <RecordContextProvider value={soi.inventory_pending}>
                          <DateField
                            source="created_at"
                            sx={{
                              fontSize: "inherit",
                            }}
                          />
                        </RecordContextProvider>
                      </td>
                    </tr>
                  );
                });
              })}
            </tbody>
          </Box>
        </TabbedShowLayout.Tab>
      ) : null}
    </TabbedShowLayout>
  );
};

export default CustomerExpandList;
