import {
  DateField,
  useRecordContext,
  RecordContextProvider,
  NumberField,
  TabbedShowLayout,
  useTranslate,
} from "react-admin";
import Box from "@mui/material/Box";

const CustomerDebtCorrectionExpandList = () => {
  const record = useRecordContext();
  const t = useTranslate();
  return (
    <TabbedShowLayout
      syncWithLocation={false}
      sx={{
        m: 5,
        backgroundClip: "padding-box",
        boxShadow: (theme) => {
          return theme.components.MuiPaper.styleOverrides.elevation1.boxShadow;
        },
        borderRadius: "4px",
        bgcolor: "background.paper",
      }}
    >
      <TabbedShowLayout.Tab label="mine.tbl_header.correction">
        <Box
          component="table"
          sx={{
            width: "100%",
            // bgcolor: "rgba(0,0,0,.04)",
            fontSize: "smaller",
            borderCollapse: "collapse",
            "& th, td": {
              padding: "6px 8px",
              border: "1px solid #dddddd",
            },
          }}
        >
          <thead>
            <tr>
              <th>{t("mine.tbl_header.sale_code")}</th>
              <th>{t("mine.tbl_header.transaction_date")}</th>
              <th>{t("mine.tbl_header.due_date")}</th>
              <th style={{ textAlign: "right" }}>
                {t("mine.tbl_header.remaining_debt")}
              </th>
              <th style={{ textAlign: "right" }}>
                {t("mine.tbl_header.correction")}
              </th>
            </tr>
          </thead>
          <tbody>
            {record.customer_debt_correction_items.map((item, idx) => {
              return (
                <tr key={item.id + idx}>
                  <td>{item.sale.code}</td>
                  <td>
                    <RecordContextProvider value={item}>
                      <DateField
                        source="sale.date"
                        sx={{
                          fontSize: "inherit",
                        }}
                      />
                    </RecordContextProvider>
                  </td>
                  <td>
                    <RecordContextProvider value={item}>
                      <DateField
                        source="sale.customer_debt.due_date"
                        sx={{
                          fontSize: "inherit",
                        }}
                      />
                    </RecordContextProvider>
                  </td>
                  <td style={{ textAlign: "right" }}>
                    <RecordContextProvider value={item}>
                      <NumberField
                        source="sale.customer_debt.remain"
                        sx={{
                          fontSize: "inherit",
                        }}
                      />
                    </RecordContextProvider>
                  </td>
                  <td style={{ textAlign: "right" }}>
                    <RecordContextProvider value={item}>
                      <NumberField
                        source="amount"
                        sx={{
                          fontSize: "inherit",
                        }}
                      />
                    </RecordContextProvider>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Box>
      </TabbedShowLayout.Tab>
      {record.transactions.length && (
        <TabbedShowLayout.Tab label="mine.tbl_header.journal">
          <Box
            component="table"
            sx={{
              width: "100%",
              // bgcolor: "rgba(0,0,0,.04)",
              fontSize: "smaller",
              borderCollapse: "collapse",
              "& th, td": {
                padding: "6px 8px",
                border: "1px solid #dddddd",
              },
            }}
          >
            <thead>
              <tr>
                <th>{t("mine.tbl_header.coa")}</th>
                <th>{t("mine.tbl_header.coa_name")}</th>
                <th>{t("mine.tbl_header.description")}</th>
                <th style={{ textAlign: "right" }}>
                  {t("mine.tbl_header.debit")}
                </th>
                <th style={{ textAlign: "right" }}>
                  {t("mine.tbl_header.credit")}
                </th>
              </tr>
            </thead>
            <tbody>
              {record.transactions.map((item, idx) => {
                return (
                  <tr key={item.id + idx}>
                    <td>{item.coa.code}</td>
                    <td>{item.coa.name}</td>
                    <td>{item.description}</td>
                    <td style={{ textAlign: "right" }}>
                      <RecordContextProvider value={item}>
                        <NumberField
                          source="debit"
                          sx={{
                            fontSize: "inherit",
                          }}
                        />
                      </RecordContextProvider>
                    </td>
                    <td style={{ textAlign: "right" }}>
                      <RecordContextProvider value={item}>
                        <NumberField
                          source="credit"
                          sx={{
                            fontSize: "inherit",
                          }}
                        />
                      </RecordContextProvider>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Box>
        </TabbedShowLayout.Tab>
      )}
      {!record.transactions.length && (
        <TabbedShowLayout.Tab label="Journal*">
          <Box
            component="table"
            sx={{
              width: "100%",
              // bgcolor: "rgba(0,0,0,.04)",
              fontSize: "smaller",
              borderCollapse: "collapse",
              "& th, td": {
                padding: "6px 8px",
                border: "1px solid #dddddd",
              },
            }}
          >
            <thead>
              <tr>
                <th>{t("mine.tbl_header.coa")}</th>
                <th>{t("mine.tbl_header.coa_name")}</th>
                <th>{t("mine.tbl_header.description")}</th>
                <th style={{ textAlign: "right" }}>
                  {t("mine.tbl_header.debit")}
                </th>
                <th style={{ textAlign: "right" }}>
                  {t("mine.tbl_header.credit")}
                </th>
              </tr>
            </thead>
            <tbody>
              {record.customer_debt_correction_journals.map((item, idx) => {
                return (
                  <tr key={item.id + idx}>
                    <td>{item.chart_of_account.code}</td>
                    <td>{item.chart_of_account.name}</td>
                    <td>{item.description}</td>
                    <td style={{ textAlign: "right" }}>
                      <RecordContextProvider value={item}>
                        <NumberField
                          source="debit"
                          sx={{
                            fontSize: "inherit",
                          }}
                        />
                      </RecordContextProvider>
                    </td>
                    <td style={{ textAlign: "right" }}>
                      <RecordContextProvider value={item}>
                        <NumberField
                          source="credit"
                          sx={{
                            fontSize: "inherit",
                          }}
                        />
                      </RecordContextProvider>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Box>
        </TabbedShowLayout.Tab>
      )}
    </TabbedShowLayout>
  );
};

export default CustomerDebtCorrectionExpandList;
