import { useMemo, useState } from "react";
import {
  List,
  TextField,
  WrapperField,
  DatagridConfigurable,
  WithRecord,
  DateField,
  BooleanField,
  BulkDeleteWithConfirmButton,
  FunctionField,
  useTranslate,
  useResourceContext,
  RecordContextProvider,
  ChipField,
  useTheme,
  usePermissions,
} from "react-admin";
import CheckCashingAddDialog from "../../components/checkCashings/create/CheckCashingAddDialog";
import CheckCashingEditDialog from "../../components/checkCashings/edit/CheckCashingEditDialog";
import checkCashingFilterList from "../../components/checkCashings/filter/checkCashingFilterList";
import TableListActions from "../../components/tableListActions/TableListActions";
import CheckCashingExpandList from "../../components/checkCashings/expand/CheckCashingExpandList";
import EditBtn from "../../components/editBtn/EditBtn";
import ConfirmBtn from "../../components/confirmBtn/ConfirmBtn";
import DoneIcon from "@mui/icons-material/Done";
// import BlockIcon from "@mui/icons-material/Block";
import CloseIcon from "@mui/icons-material/Close";
import PrintBtn from "../../components/printBtn/PrintBtn";
import PrintHandler from "../../components/printHandler/PrintHandler";
import PrintContent from "../../components/checkCashings/print/PrintContent";

const CheckCashingList = () => {
  const resource = useResourceContext();
  const { permissions } = usePermissions();
  const [openAdd, setOpenAdd] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [recordId, setRecordId] = useState();
  const [resetPrintContent, setResetPrintContent] = useState();
  const [printRecord, setPrintRecord] = useState({}); // initial value wajib object kosong untuk cek key id di dependency useeffect DeliveryNote.js
  const t = useTranslate();
  const [theme] = useTheme();

  const handleClickOpenAdd = () => {
    setOpenAdd(true);
  };
  const handleClickOpenEdit = (recId) => () => {
    setRecordId(recId);
    setOpenEdit(true);
  };
  const list = useMemo(
    () => (
      <List
        title="mine.list_title.check_cashing"
        empty={false}
        actions={
          <TableListActions
            createHandler={handleClickOpenAdd}
            exporter={false}
          />
        }
        filters={checkCashingFilterList(permissions)}
        queryOptions={{ meta: { sort: ["created_at DESC"] } }} // overide default sort
      >
        <DatagridConfigurable
          bulkActionButtons={<BulkDeleteWithConfirmButton />}
          expand={<CheckCashingExpandList />}
          sx={{
            "& .RaDatagrid-expandedPanel > td": {
              p: 0,
              bgcolor: theme !== "dark" ? "#E7EBF0" : "grey.800",
            },
          }}
        >
          {permissions && permissions.update_company ? (
            <TextField source="company.alias" label="mine.tbl_header.company" />
          ) : null}
          <TextField source="code" label="mine.tbl_header.code" />
          <DateField source="date" label="mine.tbl_header.date" />
          <TextField
            source="customer_debt_payment.giro"
            label="mine.label.check_number"
          />
          <FunctionField
            textAlign="center"
            label="mine.tbl_header.type"
            render={(record) => {
              let rec = {};
              if (record.type === 1) {
                rec.type = t("mine.label.cashing");
                rec.color = "primary";
              } else if (record.type === 2) {
                rec.type = t("mine.label.cancel");
                rec.color = "error";
              } else if (record.type === 3) {
                rec.type = t("mine.label.rejected");
                rec.color = "error";
              }
              return (
                <RecordContextProvider value={rec}>
                  <ChipField source="type" color={rec.color} size="small" />
                </RecordContextProvider>
              );
            }}
          />
          <TextField source="note" label="mine.tbl_header.note" />
          <BooleanField
            source="approved"
            label="mine.tbl_header.approved"
            textAlign="center"
          />
          <WrapperField label="mine.tbl_header.actions" textAlign="center">
            <WithRecord
              render={(record) => {
                return !record.approved ? (
                  <>
                    <EditBtn
                      editHandler={handleClickOpenEdit(record.id)}
                      sx={{ mr: 1 }}
                    />
                    <ConfirmBtn
                      title="mine.message.approve"
                      content="mine.message.approve_content"
                      label="mine.btn.approve"
                      resource={resource + "/approve"}
                      startIcon={<DoneIcon />}
                    />
                  </>
                ) : (
                  <>
                    {permissions.administrator && (
                      <ConfirmBtn
                        title="mine.message.unapprove"
                        content="mine.message.unapprove_content"
                        label="mine.label.unapprove"
                        resource={resource + "/unapprove"}
                        startIcon={<CloseIcon />}
                        color="error"
                      />
                    )}
                    <PrintBtn
                      dialogProps={{ title: "mine.dialog.print" }}
                      recordId={record.id}
                      setPrintRecord={setPrintRecord}
                      setResetPrintContent={setResetPrintContent}
                    />
                  </>
                );
              }}
            />
          </WrapperField>
        </DatagridConfigurable>
      </List>
    ),
    [theme]
  );
  return (
    <>
      {list}
      <CheckCashingAddDialog open={openAdd} setOpen={setOpenAdd} />
      <CheckCashingEditDialog
        open={openEdit}
        setOpen={setOpenEdit}
        recordId={recordId}
      />
      <PrintHandler
        record={printRecord}
        resetPrintContent={resetPrintContent}
        Content={PrintContent}
      />
    </>
  );
};

export default CheckCashingList;
