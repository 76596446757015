import {
  useRecordContext,
  RecordContextProvider,
  NumberField,
  TabbedShowLayout,
  useTranslate,
  DateField,
} from "react-admin";
import Box from "@mui/material/Box";

const AccountReceivablePcmExpandList = ({ forReport }) => {
  const record = useRecordContext();
  const t = useTranslate();
  return (
    <TabbedShowLayout
      syncWithLocation={false}
      sx={{
        m: 5,
        backgroundClip: "padding-box",
        boxShadow: (theme) => {
          return theme.components.MuiPaper.styleOverrides.elevation1.boxShadow;
        },
        borderRadius: "4px",
        bgcolor: "background.paper", // "#fff",
      }}
    >
      {record.customer_debts && record.customer_debts.length ? (
        <TabbedShowLayout.Tab label="Detail AR">
          <Box
            component="table"
            sx={{
              width: "100%",
              // bgcolor: "rgba(0,0,0,.04)",
              fontSize: "smaller",
              borderCollapse: "collapse",
              "& th, td": {
                padding: "6px 8px",
                border: "1px solid #dddddd",
              },
            }}
          >
            <thead>
              <tr>
                <th>{t("mine.label.sale_code")}</th>
                <th style={{ textAlign: "right" }}>
                  {t("mine.expand_menu.account_receivables")}
                </th>
                <th>{t("mine.label.transaction_date")}</th>
                <th>{t("mine.label.due_date")}</th>
              </tr>
            </thead>
            <tbody>
              {record.customer_debts.map((item, idx) => {
                return (
                  <tr key={item.sale.id + idx}>
                    <td>{item.sale.code}</td>
                    <td style={{ textAlign: "right" }}>
                      <RecordContextProvider value={item}>
                        <NumberField
                          source="remain"
                          sx={{
                            fontSize: "inherit",
                          }}
                        />
                      </RecordContextProvider>
                    </td>
                    <td>
                      <RecordContextProvider value={item}>
                        <DateField
                          source="sale.date"
                          sx={{
                            fontSize: "inherit",
                          }}
                        />
                      </RecordContextProvider>
                    </td>
                    <td>
                      <RecordContextProvider value={item}>
                        <DateField
                          source="due_date"
                          sx={{
                            fontSize: "inherit",
                          }}
                        />
                      </RecordContextProvider>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Box>
        </TabbedShowLayout.Tab>
      ) : null}
      {record.customer_debt_payments && record.customer_debt_payments.length ? (
        <TabbedShowLayout.Tab label="Detail PCM">
          <Box
            component="table"
            sx={{
              width: "100%",
              // bgcolor: "rgba(0,0,0,.04)",
              fontSize: "smaller",
              borderCollapse: "collapse",
              "& th, td": {
                padding: "6px 8px",
                border: "1px solid #dddddd",
              },
            }}
          >
            <thead>
              <tr>
                <th>{t("mine.label.check_number")}</th>
                <th>{t("mine.tbl_header.bank_name")}</th>
                <th style={{ textAlign: "right" }}>Total</th>
                <th>{t("mine.label.received_date")}</th>
                <th>{t("mine.label.due_date")}</th>
                <th>No. Bukti Terima</th>
              </tr>
            </thead>
            <tbody>
              {record.customer_debt_payments.map((item, idx) => {
                return (
                  <tr key={item.code + idx}>
                    <td>{item.giro}</td>
                    <td>{item.bank_name}</td>
                    <td style={{ textAlign: "right" }}>
                      <RecordContextProvider value={item}>
                        <NumberField
                          source="total"
                          sx={{
                            fontSize: "inherit",
                          }}
                        />
                      </RecordContextProvider>
                    </td>
                    <td>
                      <RecordContextProvider value={item}>
                        <DateField
                          source="date"
                          sx={{
                            fontSize: "inherit",
                          }}
                        />
                      </RecordContextProvider>
                    </td>
                    <td>
                      <RecordContextProvider value={item}>
                        <DateField
                          source="effective_date"
                          sx={{
                            fontSize: "inherit",
                          }}
                        />
                      </RecordContextProvider>
                    </td>
                    <td>{item.code}</td>
                  </tr>
                );
              })}
            </tbody>
          </Box>
        </TabbedShowLayout.Tab>
      ) : null}
    </TabbedShowLayout>
  );
};

export default AccountReceivablePcmExpandList;
