import indonesiaMessages from "ra-language-indonesian-new";
const id = {
  ra: {
    ...indonesiaMessages.ra,
    ...indonesiaMessages.ra.notification,
    action: {
      ...indonesiaMessages.ra.action,
      clear_array_input: "Kosongkan list",
      select_columns: "Kolom",
    },
    message: {
      ...indonesiaMessages.ra.message,
      clear_array_input: "Apakah anda yakin ingin membersihkan seluruh list?",
    },
  },
  resources: {
    company: {
      name: "Perusahaan",
    },
    user: {
      name: "Pengguna",
    },
    chart_of_account: {
      name: "Grafik Akun",
    },
    sales_area: {
      name: "Area Jual",
    },
    customer_type: {
      name: "Tipe Pelanggan",
    },
    supplier: {
      name: "Pemasok",
    },
    brand: {
      name: "Merek",
    },
    size: {
      name: "Ukuran",
    },
    kind: {
      name: "Jenis",
    },
    density: {
      name: "Kecerahan",
    },
    color: {
      name: "Warna",
    },
    quality: {
      name: "Kualitas",
    },
    product: {
      name: "Produk",
    },
    warehouse: {
      name: "Gudang",
    },
    transport: {
      name: "Angkutan",
    },
    order: {
      name: "Pesan |||| Pesanan",
      fields: {
        code: "Kode",
        date: "Tanggal",
        color: "Color",
      },
    },
    sale: {
      name: "Jual |||| Penjualan",
      fields: {
        code: "Kode",
        date: "Tanggal",
        color: "Color",
      },
    },
    purchase: {
      name: "Beli |||| Pembelian",
      fields: {
        code: "Kode",
        date: "Tanggal",
        color: "Color",
      },
    },
    return: {
      name: "Retur |||| Pengembalian",
      fields: {
        code: "Kode",
        date: "Tanggal",
        color: "Color",
      },
    },
    customer: {
      name: "Pelanggan",
      fields: {
        first_name: "First name",
        last_name: "Last name",
        dob: "Date of birth",
      },
    },
    price: {
      name: "Harga",
    },
    price_limit: {
      name: "Batas Harga",
      fields: {
        customer_type: "Jenis pelanggan",
        price: "Batas harga",
        note: "Keterangan",
      },
    },
    brand_price_addition: {
      name: "Harga Brand",
    },
    size_price_addition: {
      name: "Harga Ukuran",
    },
    debt_payment: {
      name: "Bayar",
    },
    debt_correction: {
      name: "Koreksi",
    },
    check_cashing: {
      name: "Pencairan Cek/Giro",
    },
    journal: {
      name: "Jurnal",
    },
    inventory_pending: {
      name: "Stok Tertahan",
    },
    close_book: {
      name: "Tutup Buku",
    },
    bad_debt: {
      name: "Piutang Macet",
    },
    bad_debt_installment: {
      name: "Cicilan",
    },
    bad_debt_correction: {
      name: "Koreksi",
    },
    bad_debt_check_cashing: {
      name: "Cair Giro",
    },
    mutation_repacking: {
      name: "Packing Ulang",
    },
    mutation_inventory_write_off: {
      name: "Pemusnahan",
    },
    mutation_change_goods_code: {
      name: "Ganti Kode Barang",
    },
    mutation_change_location: {
      name: "Pindah Antar Lokasi",
    },
    balance_sheets: {
      name: "Balance Sheets",
    },
    income_statements: {
      name: "Income Statements",
    },
    general_ledgers: {
      name: "Buku Besar",
    },
    analysis: {
      name: "Analisis",
    },
    account_receivables: {
      name: "Piutang",
    },
    account_receivable_pcms: {
      name: "Pcm",
    },
    account_receivable_cards: {
      name: "Kartu",
    },
    account_receivable_agings: {
      name: "Umur",
    },
    account_receivable_histories: {
      name: "History",
    },
    account_payables: {
      name: "Hutang",
    },
    account_payable_cards: {
      name: "Kartu",
    },
    inventories: {
      name: "Inventory",
    },
    inventory_cards: {
      name: "Kartu",
    },
    inventory_agings: {
      name: "Umur",
    },
    all_transaction_journals: {
      name: "Semua Jurnal",
    },
    activity: {
      name: "Aktifitas",
    },
    customer_debt_max_days: {
      name: "Maksimal Hari Piutang",
    },
  },
  server: {
    not_allowed: "Tidak Diperbolehkan",
    does_not_match_code_symbol:
      "Jenis yang dipilih tidak sesuai simbol di dalam Kode",
    sale_id_belongs_to_customer_id:
      "Kode jual bukan milik pelanggan yang dipilih",
    is_zero_has_non_zero:
      "Jumlah retur 0 hanya untuk salah satu SJ, utama atau gratis, keduanya harus di sertakan",
    is_main_n_free_exist:
      "Sertakan SJ lainnya, baik itu SJ utama atau SJ gratis keduanya harus ada",
    cannot_cancel_pending: "Tidak bisa membatalkan item yang tertahan ini",
    forbidden_duplication: "Tidak boleh ada pengulangan",
    match_product_code_price_on_a_date:
      "Harga tidak sesuai dengan kode produk dan tanggal yang dipilih",
    is_purchase_date_equal_or_less_than_purchase_return_date:
      "PJ belum tersedia di tanggal yang dipilih",
    less_equal_remaining_stock: "Stok tidak cukup",
    less_equal_purchased_quantity:
      "Total yang telah diretur melebihi yang diterima",
    less_equal_total_purchase_order:
      "Total yang diterima melebihi jumlah yang diorder",
    equal_greater_than_item_date: "Tidak boleh kurang dari tanggal pesan",
    unapproved_previous_sale_not_exist: "Approve SJ sebelumnya terlebih dahulu",
    unique_start_date_for_quality_filter_by:
      "Duplikat tanggal mulai pada salah satu kualitas (KW)",
    current_month_must_be_greater: "Hanya bisa tutup bulan yang telah lewat",
    calculated_but_not_closed_yet: "Aneh, telah dihitung tapi belum tutup buku", // tiap akun sdh di hitung saldonya di monthly balance, tp status table close_books belum tutup
    is_book_open: "Periode ini sudah tutup buku",
    is_allowed_to_be_parent: "Akun ini sudah pernah dijurnal sebagai anak",
    date_is_not_greater: "Cek tanggal, saat itu transaksi ini belum ada",
    close_previous_month_first: "Tutup periode sebelumnya terlebih dahulu",
    is_debit_equal_credit: "Total debit harus sama dengan total kredit",
    less_equal_with_order_quantity_db:
      "Harus kurang atau sama dengan sisa jumlah pesanan",
    either_debit_or_credit_is_zero:
      "Debit dan kredit, keduanya tidak boleh nol",
    cannot_use_parent_for_journal: "Akun induk tidak bisa digunakan menjurnal",
    is_unapproved: "Ini belum disetujui",
    need_exactly_three_files: "Butuh tiga file",
    approved_this_transaction_first: "Setujui transaksi ini dulu: %{code}",
    open_next_month_first: "Buka periode setelahnya dulu",
    is_coa_a_child: "Jangan gunakan induk, pilihlah COA anak",
    has_active_price: "Tidak ada harga untuk tanggal yang dipilih",
    must_be_of_same_group: "Produk harus dari jenis group yang sama",
    must_be_of_same_supplier:
      "Semua produk harus dari supplier yang sama (penting untuk umur piutang berdasarkan supplier)",
    notes_receivable_for_giro: "Notes receivable untuk pembayaran tipe giro",
    belongs_to_other_supplier: "Produk ini pernah dibeli dari pemasok lain",
    no_unapproved_rj: "Ada RJ yang belum di setujui",
    no_unapproved_kp: "Ada KP yang belum di setujui",
    no_unapproved_arp: "Ada ARP yang belum di setujui",
    no_unapproved_cas: "Ada CAS yang belum di setujui",
    no_unapproved_bdk: "Ada BDK yang belum di setujui",
    no_unapproved_bdp: "Ada BDP yang belum di setujui",
    no_unapproved_cbd: "Ada CBD yang belum di setujui",
    no_uncashing_check: "Ada ARP giro yang belum di cairkan",
  },
  mine: {
    filter: {
      search_all: "Cari semua",
    },
    expand_menu: {
      purchase: "Pembelian",
      sale: "Penjualan",
      order: "Pesan",
      product_code: "Kode Produk",
      factory_debt: "Hutang ke pabrik",
      customer_debt: "Piutang pelanggan",
      bad_debt: "Piutang Macet",
      mutation: "Mutasi",
      report: "Laporan",
      general_ledger: "General Ledger",
      analysis: "Analisis",
      account_receivables: "Piutang",
      account_payables: "Hutang",
      inventories: "Inventory",
    },
    message: {
      approve: "Setujui",
      unapprove: "Batal Setujui",
      cancel: "Batalkan",
      close_book: "Tutup Buku",
      open_book: "Buka Buku",
      approve_content: "Apakah anda yakin ingin menyetujui data ini?",
      unapprove_content: "Apakah anda yakin ingin batal menyetujui data ini?",
      cancel_content: "Apakah anda yakin ingin membatalkan data ini?",
      close_book_content: "Apakah anda yakin ingin menutup periode ini?",
      open_book_content: "Apakah anda yakin ingin membuka periode ini?",
      transport_according_to: "Isi berdasarkan jumlah mobil atau jumlah kg",
      percentage_value: "Isi dengan nilai desimal dari persentase",
      // over_due_debt:
      //   "Hanya untuk penjualan yg belum lunas, kecuali pelanggan punya hutang melebihi batas waktu",
      print_again:
        "Data ini sudah pernah dicetak %{count} kali, ketik alasan anda kenapa ingin cetak lagi",
      print_content: "Apakah anda yakin ingin cetak data ini?",
    },
    dialog: {
      create_sale_order: "Buat Pesanan Penjualan",
      edit_sale_order: "Rubah Pesanan Penjualan",
      create_sale: "Buat Penjualan",
      edit_sale: "Rubah Penjualan",
      create_sale_return: "Buat Retur Penjualan",
      edit_sale_return: "Rubah Retur Penjualan",
      create_promotion: "Buat Promosi",
      edit_promotion: "Rubah Promosi",
      create_factory_debt_payment: "Buat Pembayaran Hutang Ke Pabrik",
      edit_factory_debt_payment: "Rubah Pembayaran Hutang Ke Pabrik",
      create_factory_debt_correction: "Buat Koreksi Hutang Ke Pabrik",
      edit_factory_debt_correction: "Rubah Koreksi Hutang Ke Pabrik",
      create_customer_debt_payment: "Buat Pembayaran Piutang Pelanggan",
      edit_customer_debt_payment: "Rubah Pembayaran Piutang Pelanggan",
      create_customer_debt_correction: "Buat Koreksi Piutang Pelanggan",
      edit_customer_debt_correction: "Rubah Koreksi Piutang Pelanggan",
      create_check_cashing: "Buat Pencairan Cek/Giro",
      edit_check_cashing: "Rubah Pencairan Cek/Giro",
      create_journal: "Buat Jurnal",
      edit_journal: "Rubah Jurnal",
      create_bad_debt: "Buat Piutang Macet",
      edit_bad_debt: "Rubah Piutang Macet",
      create_product: "Buat Produk",
      edit_product: "Rubah Produk",
      create_bad_debt_installment: "Buat Cicilan Piutang Macet",
      edit_bad_debt_installment: "Rubah Cicilan Piutang Macet",
      create_bad_debt_correction: "Buat Koreksi Piutang Macet",
      edit_bad_debt_correction: "Rubah Koreksi Piutang Macet",
      create_bad_debt_check_cashing: "Buat Pencairan Cek/Giro Piutang Macet",
      edit_bad_debt_check_cashing: "Rubah Pencairan Cek/Giro Piutang Macet",
      create_purchase_order: "Buat Pesan Pembelian",
      edit_purchase_order: "Rubah Pesan Pembelian",
      create_mutation_repacking: "Buat Packing Ulang",
      edit_mutation_repacking: "Rubah Packing Ulang",
      create_mutation_inventory_write_off: "Buat Pemusnahan Barang",
      edit_mutation_inventory_write_off: "Rubah Pemusnahan Barang",
      print_delivery_note: "Cetak Surat Jalan",
      print: "Cetak",
      create_price: "Buat Harga",
      edit_price: "Rubah Harga",
      create_transport: "Buat Transport",
      edit_transport: "Rubah Transport",
      create_kind: "Buat Jenis",
      edit_kind: "Rubah Jenis",
      create_customer_debt_max_day: "Buat Maksimal Hari Piutang",
      edit_customer_debt_max_day: "Rubah Maksimal Hari Piutang",
      create_brand_price_addition: "Buat Harga Brand",
      edit_brand_price_addition: "Rubah Harga Brand",
      create_size_price_addition: "Buat Harga Ukuran",
      edit_size_price_addition: "Rubah Harga Ukuran",
    },
    notification: {
      "403_error": "Tidak dapat merubah data yang telah disetujui",
      not_allowed: "Tidak Diperbolehkan",
      Not_Found: "Tidak ditemukan",
      Free_package_is_unedited:
        "SJ gratis tidak bisa dirubah, coba rubah SJ utamanya",
      cannot_delete_used_data: "Tidak dapat menghapus data terpakai",
      Internal_server_error: "Kesalahan internal server",
    },
    label: {
      account: "Akun",
      amount: "Nominal",
      code: "Kode",
      company: "Perusahaan",
      correction: "Koreksi",
      customer: "Pelanggan",
      credit: "Kredit",
      create: "Buat",
      date: "Tanggal",
      debt: "Hutang",
      edit: "Rubah",
      cancel: "Pembatalan",
      cashing: "Pencairan",
      check_number: "No. Cek / Giro",
      coa_code: "Kode COA",
      customer_debt: "Piutang",
      customer_debts: "Daftar Piutang",
      debit: "Debit",
      discount_per_box: "Diskon / box",
      due_date: "Tanggal jatuh tempo",
      end_date: "Tanggal berakhir",
      end_code: "Kode akhir",
      journal: "Jurnal",
      name: "Nama",
      note: "Keterangan",
      order_quantity: "Jumlah pesanan",
      package: "Paket",
      persentage: "Persentase",
      percentage_in_decimal: "Persentase dalam desimal",
      price_per_box: "Harga / box",
      product_code: "Kode produk",
      promotions: "Promosi",
      promotion: "Promosi",
      purchase_order: "Pesan beli",
      purchase_number: "Nomer beli",
      received_quantity: "Jumlah diterima",
      rejected: "Penolakan",
      sales_number: "Nomer penjualan",
      sent_quantity: "Jumlah kirim",
      special_discount_per_box: "Spesial diskon / box",
      start_date: "Tanggal mulai",
      start_code: "Kode awal",
      total_correction: "Jumlah koreksi",
      total_vehicle: "Jumlah angkutan",
      transaction_date: "Tanggal transaksi",
      transport: "Angkutan",
      type: "Tipe",
      settled: "Lunas",
      unsettled: "Belum Lunas",
      sale_code: "Kode jual",
      sale_item: "Item jual",
      warehouse: "Gudang",
      return_quantity: "Jumlah retur",
      price: "Harga",
      bad_debt: "Piutang Macet",
      installment: "Cicilan",
      payment: "Pembayaran",
      remaining_debt: "Sisa Hutang",
      remain: "Sisa",
      total_payment: "Total Bayar",
      supplier: "Pemasok",
      max_payment_days: "Max Hari Pembayaran",
      quality: "Kualitas",
      quantity: "Jumlah",
      stocktake: "Opname",
      sample: "Sampel",
      ref_number: "Nomer Surat Jalan",
      cogs: "HPP",
      kind: "Jenis",
      active: "Aktif",
      operator_name: "Nama Operator",
      warehouse_broke: "Pecah Gudang",
      transfer_between_depo: "Mutasi Antar Depo",
      warning: "Peringatan",
      reason: "Alasan",
      cus_sup: "Pelanggan/Pemasok",
      brand: "Merek",
      size: "Ukuran",
      motif: "Motif",
      density: "Kecerahan",
      color: "Warna",
      buy_price: "Harga Beli",
      sell_price: "Harga Jual",
      transport_discount: "Diskon Angkutan / box",
      cost: "Harga",
      cost_type: "Tipe Harga",
      capacity: "Kapasitas",
      plate: "Plat",
      plate_number: "Plat Nomer",
      sale_order: "Pesan Jual",
      balance: "Balance",
      return: "Retur",
      sent: "Kirim",
      previous: "Sebelumnya",
      report: "Laporan",
      transaction: "Transaksi",
      general_ledger_detail: "Detail Buku Besar",
      show_detail: "Detail",
      current_balance: "Akhir",
      income_statement: "Income Statement",
      balance_sheet: "Balance Sheet",
      tax: "Pajak",
      percentage: "Persentase",
      permission: "Permission",
      role: "Role",
      symbol: "Simbol",
      "13_chars": "13 karakter",
      "10_chars": "10 karakter contoh: G40SPW=LCR",
      loan_limit: "Limit Piutang",
      loan_tenor: "Pelunasan (Hari)",
      loan_tenor_tolerance: "Toleransi (Hari)",
      pic: "Penanggung Jawab",
      address: "Alamat",
      closed: "Telah Tutup",
      month: "Bulan",
      year: "Tahun",
      period: "Periode",
      closed_date: "Tanggal Tutup",
      open: "Buka",
      close: "Tutup",
      nature: "Normal",
      class: "Kelas",
      parent: "Induk",
      delivery_note: "Nomer Ref.",
      order_no: "No. Pesan",
      created_at: "Dibuat Pada",
      join_on: "Bergabung Pada",
      customer_code: "Kode Pelanggan",
      customer_name: "Nama Pelanggan",
      supplier_code: "Kode Pemasok",
      supplier_name: "Nama Pemasok",
      initial_balance: "Saldo Awal",
      final_balance: "Saldo Akhir",
      total_day: "Total Hari",
      dashboard_title: "👋 Selamat datang di Acura",
      dashboard_subtitle:
        "Ini adalah generasi kedua dari Acura, sebuah aplikasi akunting yang dibuat oleh CJFI untuk Depo-Deponya (cabang). Hubungi Divisi Depo Support jika kamu membutuhkan pendampingan atau hubungi Divisi IT jika kamu menemukan masalah teknis. 😁",
      dashboard_contact_dp_support: "Hubungi Depo Support",
      dashboard_contact_it: "Hubungi IT",
      upload: "Unggah",
      sales_area_first: "Pastikan menu area jual sudah terisi",
      upload_two_file_customer_and_address:
        "Upload dua file sekaligus (pelanggan dan alamat pelanggan)",
      upload_two_file_transport_and_its_detail:
        "Upload dua file sekaligus (angkutan dan detailnya)",
      upload_two_files: "Upload dua file sekaligus",
      upload_three_files: "Upload tiga file sekaligus",
      initial: "Awal",
      other: "Lainnya",
      final: "Akhir",
      according_to_cogs: "Berdasarkan HPP",
      value_in: "Nilai Masuk",
      value_out: "Nilai Keluar",
      other_value: "Nilai Lain",
      initial_value: "Nilai Awal",
      final_value: "Nilai Akhir",
      net: "Bersih",
      gross: "Kotor",
      value: "Nilai",
      box: "Boks",
      due: "Jatuh Tempo",
      gross_purchase: "Pembelian Kotor",
      gross_sale: "Penjualan Kotor",
      net_purchase: "Pembelian Bersih",
      net_sale: "Penjualan Bersih",
      goods_code: "Kode Barang",
      day: "Hari",
      age: "Umur",
      approve: "Setujui",
      unapprove: "Batal Setujui",
      account_receivable_net: "Piutang Bersih",
      received_date: "Tanggal Terima",
      print_invoice: "Cetak Invoice",
    },
    radio_btn: {
      return_warehouse: "Masuk Gudang",
      return_broken: "Tidak Masuk Gudang / Rusak",
    },
    list_title: {
      factory_debt_payment: "Bayar Hutang",
      factory_debt_correction: "Koreksi Hutang",
      customer_debt_payment: "Bayar Piutang",
      customer_debt_correction: "Koreksi Piutang",
      purchase_order: "Pesan Beli",
      purchase_return: "Retur Beli",
      sale_order: "Pesan Jual",
      sale_return: "Retur Jual",
      check_cashing: "Pencairan Cek/Giro",
      journal: "Jurnal",
      bad_debt: "Piutang Macet",
      bad_debt_installment: "Cicilan Piutang Macet",
      bad_debt_correction: "Koreksi Piutang Macet",
      bad_debt_check_cashing: "Pencairan Cek/Giro Piutang Macet",
      mutation_repacking: "Packing Ulang",
      mutation_inventory_write_off: "Pemusnahan Barang",
      all_transaction_journal: "Semua Jurnal Transaksi",
      general_ledger: "Buku Besar",
      inventory_pending: "Stok Tertahan",
      account_receivable_pcm: "Kartu Piutang + Pcm",
      account_receivable_card: "Kartu Piutang",
      account_receivable_aging: "Umur Piutang",
      account_receivable_history: "Historis Pembayaran Piutang",
      account_payable_card: "Kartu Hutang",
      inventory_card: "Kartu Inventory",
      inventory_aging: "Umur Inventory",
    },
    tbl_header: {
      payment: "Pembayaran",
      journal: "Jurnal",
      check: "Giro",
      approved: "Disetujui",
      date: "Tanggal",
      due_date: "Jatuh Tempo",
      remaining_debt: "Sisa Hutang",
      transaction_date: "Tanggal Transaksi",
      description: "Deskripsi",
      debit: "Debit",
      credit: "Kredit",
      check_cashing_date: "Tanggal Pencairan",
      bank_name: "Nama Bank",
      check_number: "Giro No.",
      note: "Keterangan",
      actions: "Aksi",
      sale_code: "Kode Jual",
      code: "Kode",
      company: "Perusahaan",
      customer: "Pelanggan",
      type: "Tipe",
      coa: "Akun",
      coa_name: "Nama Akun",
      correction: "Koreksi",
      customer_debt_payment_code: "Kode Bayar Piutang",
      bad_debt_installment_code: "Kode Cicil Piutang",
      product_code: "Kode Produk",
      product_name: "Nama Produk",
      price: "harga",
      warehouse: "Gudang",
      sent_qty: "Jumlah Kirim",
      return_qty: "Jumlah Retur",
      delivery_note: "Nomer Surat Jalan",
      product: "Produk",
      name: "Nama",
      kind: "Jenis",
      stock: "Stok",
      location: "Lokasi",
      total_in: "Masuk",
      total_out: "Keluar",
    },
    btn: {
      approve: "Setujui",
    },
    tooltip: {
      total_inventory_write_off: "Nilainya bisa berbeda setelah di approve",
    },
  },
};
export default id;
