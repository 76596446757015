import {
  TextInput,
  maxLength,
  number,
  required,
  useTranslate,
} from "react-admin";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import InputAdornment from "@mui/material/InputAdornment";

const ReasonInput = () => {
  const t = useTranslate();

  return (
    <>
      <Box
        sx={{
          fontWeight: "500",
          textTransform: "uppercase",
          pb: "20px",
        }}
      >
        <Typography
          component="span"
          sx={{
            backgroundColor: "#d32f2f",
            color: "#fff",
            padding: "1px 8px 0",
            borderRadius: "4px",
          }}
        >
          {t("mine.label.warning")}
        </Typography>
        {": "}
        <Typography component="span">
          Pastikan sudah cek data retur jual dan cash discount
        </Typography>
      </Box>
      <TextInput
        fullWidth
        autoFocus
        source="due"
        label="mine.label.due"
        variant="outlined"
        validate={[required(), number()]}
        sx={{ minWidth: 300 }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {t("mine.label.day")}
            </InputAdornment>
          ),
        }}
      />
      <TextInput
        fullWidth
        source="plate"
        label="mine.label.plate_number"
        variant="outlined"
        validate={[required(), maxLength(20)]}
        sx={{ minWidth: 300 }}
      />
    </>
  );
};
export default ReasonInput;
